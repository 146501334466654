import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Profile } from 'src/app/general-components/profile/profile';
import { EmptySupplier, Supplier } from './supplier';
import { SupplierService } from './supplier.service';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent implements OnInit {
  @ViewChild('supplierName') supplierName!: NgModel;
  @ViewChild('supplierId') supplierId!: NgModel;
  @ViewChildren('sortIcons') sortIcons!: QueryList<ElementRef>;

  suppliers: Supplier[] = [];
  selectedSupplier: Supplier;
  profile: Profile | undefined;

  deleteCandidateSupplier?: Supplier;
  createMode: boolean = true;
  isLoading: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };
  SupplierCRUDFeedbackIdentifier = 'supplierFeedback';
  generalFeedbackIdentifier = 'generalFeedback';

  searchText: string = '';
  sortByNameAsc: boolean = false;
  sortBySupplierIdAsc: boolean = false;
  sortByLastModifiedAsc: boolean = false;
  sortByLastUserModifiedAsc: boolean = false;

  page: number = 1;
  constructor(
    private renderer: Renderer2,
    private supplierService: SupplierService,
    private authService: AuthService,
    private router: Router
  ) {
    this.selectedSupplier = EmptySupplier();
  }

  ngOnInit(): void {
    this.getSuppliers();
    this.profile = this.authService.getUserInfo();
  }

  getSuppliers() {
    this.isLoading = true;
    this.supplierService.getSuppliers().subscribe(
      (suppliers) => {
        this.suppliers = suppliers;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is iets foutgegaan bij het ophalen van de leveranciers',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  editSupplier(supplier: Supplier) {
    this.selectedSupplier = supplier;
    this.feedbackSettings.show = false;
    this.createMode = false;
  }

  createSupplier(supplier: Supplier) {
    supplier.lastUserModified =
      this.profile?.firstName + ' ' + this.profile?.lastName;
    this.supplierService.createSupplier(supplier).subscribe(
      (supplier) => {
        this.addToSuppliers(supplier);
        this.updateFeedback(
          this.SupplierCRUDFeedbackIdentifier,
          FeedbackType.succes,
          true,
          `De leverancier ${supplier.name} is aangemaakt!`,
          undefined,
          true
        );
      },
      (error) => {
        console.error(error);
        this.updateFeedback(
          this.SupplierCRUDFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is *iets* fout gegaan bij het aanmaken van de leverancier',
          undefined,
          true
        );
      }
    );
    this.clearSelectedSupplier();
  }
  updateSupplier(supplier: Supplier) {
    supplier.lastUserModified =
      this.profile?.firstName + ' ' + this.profile?.lastName;
    this.supplierService.updateSupplier(supplier).subscribe(
      (supplier) => {
        this.updateFeedback(
          this.SupplierCRUDFeedbackIdentifier,
          FeedbackType.succes,
          true,
          `De leverancier ${supplier.name} is aangepast!`,
          undefined,
          true
        );
      },
      (error) => {
        console.error(error);
        this.updateFeedback(
          this.SupplierCRUDFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is *iets* fout gegaan bij het aanpassen van de special',
          undefined,
          true
        );
      }
    );
    this.clearSelectedSupplier();
  }

  deleteSupplier(supplier: Supplier) {
    this.supplierService.deleteSupplier(supplier).subscribe(
      (response) => {
        this.updateFeedback(
          this.SupplierCRUDFeedbackIdentifier,
          FeedbackType.succes,
          true,
          `De leverancier ${supplier.name} is verwijderd!`,
          undefined,
          true
        );
        this.clearDeleteCandidate();
        this.removeFromSuppliers(supplier);
      },
      (error) => {
        console.error(error);
        this.updateFeedback(
          this.SupplierCRUDFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is *iets* fout gegaan bij het verwijderen van de special',
          undefined,
          true
        );
      }
    );

    this.deleteCandidateSupplier = undefined;
  }

  setDeleteCandidate(supplier: Supplier) {
    this.deleteCandidateSupplier = supplier;
    this.feedbackSettings.show = false;
  }

  clearDeleteCandidate() {
    this.deleteCandidateSupplier = undefined;
  }

  addToSuppliers(supplier: Supplier) {
    supplier.lastModified = supplier.lastModified?.substring(0, 19);
    this.suppliers.push(supplier);
    this.sortByField('lastModified', false);
  }

  removeFromSuppliers(supplier: Supplier) {
    this.suppliers.forEach((supplierElement, index) => {
      if (supplierElement.id === supplier.id) {
        this.suppliers.splice(index, 1);
      }
    });
  }

  clearSelectedSupplier() {
    this.selectedSupplier = EmptySupplier();
    this.supplierId.reset();
    this.supplierName.reset();
    this.createMode = true;
  }

  updateFeedback(
    identifier: string,
    type: FeedbackType,
    show: boolean,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ) {
    this.feedbackSettings.identifier = identifier;
    this.feedbackSettings.type = type;
    this.feedbackSettings.show = show;
    this.feedbackSettings.message = message;
    this.feedbackSettings.buttonText = buttonText;
    this.feedbackSettings.closeButton = closeButton;
  }

  handleFeedbackOutput(identifier: string) {
    if (identifier == this.generalFeedbackIdentifier) {
      this.router.navigate(['home']);
    } else {
      this.feedbackSettings.show = false;
    }
  }

  isSupplierIdValid(): boolean {
    if (this.supplierId == undefined || this.supplierId.valid == null) {
      return false;
    }

    return this.supplierId.valid && this.isSupplierIdUnique();
  }

  isSupplierIdUnique(): boolean {
    let foundSupplier: Supplier | undefined = this.suppliers.find((s) => {
      if (this.createMode) {
        return s.supplierId! === this.selectedSupplier.supplierId!;
      } else {
        return (
          s.supplierId! === this.selectedSupplier.supplierId! &&
          s.id != this.selectedSupplier.id
        );
      }
    });
    return foundSupplier == undefined;
  }

  //these methods are not tested
  isFormValid(): boolean {
    if (!this.supplierName || !this.supplierId) {
      return false;
    } else {
      return this.supplierName.valid! && this.isSupplierIdValid();
    }
  }

  sortByField(field: string, ascendingOrder: boolean) {
    switch (field) {
      case 'name': {
        this.sortByNameAsc = ascendingOrder;
        this.sortBySupplierIdAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByLastUserModifiedAsc = false;
        if (ascendingOrder) {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.name < supplier2.name) return -1;
            if (supplier1.name > supplier2.name) return 1;
            return 0;
          });
        } else {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.name < supplier2.name) return 1;
            if (supplier1.name > supplier2.name) return -1;
            return 0;
          });
        }
        break;
      }
      case 'supplierId': {
        //TODO: this can be better but I dont care right now, sorry
        this.sortByNameAsc = false;
        this.sortBySupplierIdAsc = ascendingOrder;
        this.sortByLastModifiedAsc = false;
        this.sortByLastUserModifiedAsc = false;
        if (ascendingOrder) {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.supplierId! < supplier2.supplierId!) return -1;
            if (supplier1.supplierId! > supplier2.supplierId!) return 1;
            return 0;
          });
        } else {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.supplierId! < supplier2.supplierId!) return 1;
            if (supplier1.supplierId! > supplier2.supplierId!) return -1;
            return 0;
          });
        }
        break;
      }
      case 'lastUserModified': {
        this.sortByNameAsc = false;
        this.sortBySupplierIdAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByLastUserModifiedAsc = ascendingOrder;
        if (ascendingOrder) {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.lastUserModified! < supplier2.lastUserModified!)
              return -1;
            if (supplier1.lastUserModified! > supplier2.lastUserModified!)
              return 1;
            return 0;
          });
        } else {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.lastUserModified! < supplier2.lastUserModified!)
              return 1;
            if (supplier1.lastUserModified! > supplier2.lastUserModified!)
              return -1;
            return 0;
          });
        }
        break;
      }
      case 'lastModified': {
        this.sortByNameAsc = false;
        this.sortBySupplierIdAsc = false;
        this.sortByLastModifiedAsc = ascendingOrder;
        this.sortByLastUserModifiedAsc = false;
        if (ascendingOrder) {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.lastModified! < supplier2.lastModified!) return -1;
            if (supplier1.lastModified! > supplier2.lastModified!) return 1;
            return 0;
          });
        } else {
          this.suppliers = this.suppliers.sort((supplier1, supplier2) => {
            if (supplier1.lastModified! < supplier2.lastModified!) return 1;
            if (supplier1.lastModified! > supplier2.lastModified!) return -1;
            return 0;
          });
        }
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    this.updateSortingHighlight(`${field}${ascendingOrder ? 'Asc' : 'Desc'}`);
  }

  updateSortingHighlight(highlightElement: string) {
    this.sortIcons.forEach((element: ElementRef) => {
      if (element.nativeElement.id == highlightElement) {
        this.renderer.addClass(element.nativeElement, 'highlight');
      } else {
        this.renderer.removeClass(element.nativeElement, 'highlight');
      }
    });
  }
}
