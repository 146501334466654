import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SOS_EMAIL_SERVICE_URL } from 'src/app/injection-tokens';
import { SOSCustomer } from './sos-customer';
import { Observable } from 'rxjs';

interface SOSEmailQuery {
  yesterday?: boolean;
  ordernumbersText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SOSEmailService {
  constructor(
    @Inject(SOS_EMAIL_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getSOSCustomersByKrampOrdernumbers(
    ordernumbersText: string
  ): Observable<SOSCustomer[]> {
    return this.getSOSCustomers('kramp', {
      ordernumbersText: ordernumbersText,
    });
  }

  getSOSCustomersByINDIOrdernumbers(
    ordernumbersText: string
  ): Observable<SOSCustomer[]> {
    return this.getSOSCustomers('indi', {
      ordernumbersText: ordernumbersText,
    });
  }

  getSOSCustomersForOrdersWithoutResponse(
    yesterday: boolean
  ): Observable<SOSCustomer[]> {
    return this.getSOSCustomers('indi/no-response', {
      yesterday: yesterday,
    });
  }

  getSOSCustomers(
    type: string,
    sosEmailQuery: SOSEmailQuery
  ): Observable<SOSCustomer[]> {
    const headers = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.post<SOSCustomer[]>(
      this.serviceURL + '/' + type,
      sosEmailQuery,
      {
        headers: headers,
      }
    );
  }
}
