import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Manco } from '../manco-to-check-up-on';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-manco-form',
  templateUrl: './manco-form.component.html',
  styleUrls: ['./manco-form.component.scss'],
})
export class MancoFormComponent implements OnChanges {
  @Input() data?: Manco;
  @Output() formSubmit = new EventEmitter<Manco>();
  @ViewChild('ticketNumberInput')
  ticketNumberInput!: ElementRef<HTMLInputElement>;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      ticketNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      customerNumber: [
        '',
        [
          Validators.maxLength(20), //
          Validators.pattern('^$|^[0-9]{6,8}$'),
        ],
      ],
      caseNumber: ['', [Validators.maxLength(20)]],
      treatmentDate: [''],
      checkDate: [''],
      booked: [false],
      completed: [false],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && this.data) {
      const dataWithDates = {
        ...this.data,
        treatmentDate: this.data.treatmentDate
          ? formatDate(this.data.treatmentDate, 'yyyy-MM-dd', 'nl-NL')
          : '',
        checkDate: this.data.checkDate
          ? formatDate(this.data.checkDate, 'yyyy-MM-dd', 'nl-NL')
          : '',
      };
      this.form.patchValue(dataWithDates);

      // Focus on the ticket number input when the dialog is shown
      this.ticketNumberInput.nativeElement.focus();
    }
  }

  onSubmit() {
    if (this.form.valid && this.data) {
      const updatedData: Manco = {
        ...this.data,
        ...this.form.value,
      };
      this.formSubmit.emit(updatedData);
    }
  }
}
