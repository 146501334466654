import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SPECIALS_SERVICE_URL } from 'src/app/injection-tokens';
import { INDISpecial } from './indi-special';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class INDISpecialsService {
  constructor(
    @Inject(SPECIALS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  createINDISpecial(INDISpecial: INDISpecial): Observable<INDISpecial> {
    return this.http.post<INDISpecial>(this.serviceURL + '/indi', INDISpecial);
  }
  getINDISpecial(id: number): Observable<INDISpecial> {
    return this.http.get<INDISpecial>(`${this.serviceURL}/indi/${id}`);
  }
  getINDISpecials(): Observable<INDISpecial[]> {
    return this.http.get<INDISpecial[]>(this.serviceURL + '/indi');
  }
}
