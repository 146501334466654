<h1 class="text-center border-bottom pb-3">Overzicht te verwerken retouren</h1>
<div class="d-flex flex-column align-item-center my-3">
  <div class="align-self-center">
    De statusbestanden worden nu automagisch verwerkt.
    <app-processed-files
      [processedFiles]="processedFiles"
      [isError]="processedFileError"
    ></app-processed-files>

    Hieronder staan de nog te verwerken retouren:
  </div>
</div>
<div class="row" [ngClass]="{ 'd-none': feedbackSettings.show || isLoading }">
  <div *ngIf="returnItems.length > 0; else noResults">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="width-5">#</th>
          <th scope="col" class="width-10">Orderdatum</th>
          <th scope="col" class="width-10">Retournummer</th>
          <th scope="col" class="width-10">Artikelnummer</th>
          <th scope="col" class="width-20">Omschrijving</th>
          <th scope="col" class="width-10">Ordernummer</th>
          <th scope="col" class="width-10">Klantreferentie</th>
          <th scope="col" class="width-20">Klantnaam</th>
          <th scope="col" class="width-5">Aantal</th>
          <th scope="col" class="width-5">Verwerkt?</th>
        </tr>
      </thead>
      <tbody>
        <tr
          app-return-item-line
          [returnItem]="returnItem"
          [index]="i"
          *ngFor="let returnItem of returnItems; let i = index"
        ></tr>
      </tbody>
    </table>
    <pagination-controls
      (pageChange)="page = $event"
      previousLabel="Vorige"
      nextLabel="Volgende"
      [autoHide]="true"
    ></pagination-controls>
  </div>
  <ng-template #noResults>
    <div class="d-flex justify-content-center mt-5">
      <div class="p-5 border">
        <img [src]="'assets/cat.png'" height="100" />
        <span class="ms-4 fs-4"> Goed bezig, de lijst is leeg! </span>
      </div>
    </div>
  </ng-template>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback
    [(settings)]="feedbackSettings"
    (update)="returnHome()"
  ></app-feedback>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
