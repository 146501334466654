import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeValidCharacters',
})
export class RemoveValidCharactersPipe implements PipeTransform {
  transform(value: string, regex: string): string {
    const invalidChars = value.replace(new RegExp(regex, 'g'), '');

    if (invalidChars.length === 0) {
      return '';
    }

    const normalChars = invalidChars.replace(/[\s,]/g, '');
    const uniqueNormalCharsArray = [...new Set(normalChars.split(''))];

    const specialCharsDescriptions =
      this.getSpecialCharsDescriptions(invalidChars);

    return this.formatCombinedText(
      specialCharsDescriptions,
      uniqueNormalCharsArray
    );
  }

  private getSpecialCharsDescriptions(invalidChars: string): string[] {
    const descriptions = [];

    if (invalidChars.includes(' ')) descriptions.push('spaties');
    if (invalidChars.includes('\t')) descriptions.push('tabs');
    if (invalidChars.includes(',')) descriptions.push("komma's");
    const invalidCharsNoSpacesAndTabs = invalidChars.replace(/[\t ]/g, '');
    if (/\s/.test(invalidCharsNoSpacesAndTabs)) {
      descriptions.push('andere witruimten');
    }

    return descriptions;
  }

  private formatCombinedText(
    specialCharsDescriptions: string[],
    uniqueNormalCharsArray: string[]
  ): string {
    const allTexts = [...specialCharsDescriptions, ...uniqueNormalCharsArray];

    if (allTexts.length <= 1) {
      return allTexts.join('');
    }

    return allTexts.join(', ').replace(/, ([^,]+)$/, ' en $1');
  }
}
