import { Pipe, PipeTransform } from '@angular/core';
import { Supplier } from './supplier';

@Pipe({
  name: 'searchSuppliers',
})
export class SearchSuppliersPipe implements PipeTransform {
  transform(items: Supplier[], searchText: string): Supplier[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.name.toLowerCase().includes(searchText) ||
        String(it.supplierId).toLowerCase().includes(searchText)
      );
    });
  }
}
