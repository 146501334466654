export interface Profile {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export const EmptyProfile = (): Profile => ({
  emailAddress: '',
  firstName: '',
  lastName: '',
});
