<div class="card" style="width: 18rem">
  <div class="card-body">
    <h5 class="card-title">Profiel</h5>
    <h6 class="card-subtitle mb-2 text-muted">je profiel info</h6>
    <p class="card-text">je email: {{ profile.emailAddress }}</p>
    <p class="card-text">
      je naam: {{ profile.firstName }} {{ profile.lastName }}
    </p>
  </div>
</div>
