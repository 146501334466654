import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcDate',
})
export class utcDatePipe extends DatePipe implements PipeTransform {
  transform(value: null | undefined, args?: string): null;
  transform(value: string | number | Date, args: string): string | null;
  transform(value: any, args?: string): string | null {
    if (!value.endsWith('Z')) {
      value = value + 'Z';
    }
    if (!isDutchDaylightSavingTime(value)) {
      return super.transform(value, args, '+0100', 'nl-NL');
    }
    return super.transform(value, args, '+0200', 'nl-NL');
  }
}

function isDutchDaylightSavingTime(date: string): boolean {
  const utcDate = new Date(date);
  const currentYear = utcDate.getUTCFullYear();

  const daylightSavingTimeStart = new Date(
    Date.UTC(currentYear, 2, 31 - (((5 * currentYear) / 4 + 4) % 7), 1)
  );
  const daylightSavingTimeEnd = new Date(
    Date.UTC(currentYear, 9, 31 - (((5 * currentYear) / 4 + 1) % 7), 1)
  );

  return utcDate >= daylightSavingTimeStart && utcDate < daylightSavingTimeEnd;
}
