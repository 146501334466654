import { Component, EventEmitter, Output } from '@angular/core';
import { MancoFilters } from './manco-filter.pipe';

@Component({
  selector: 'app-manco-filter',
  templateUrl: './manco-filter.component.html',
  styleUrls: ['./manco-filter.component.scss'],
})
export class MancoFilterComponent {
  possibleFilters = Object.values(MancoFilters);
  selectedFilter: MancoFilters = MancoFilters.AllMancos;

  @Output() selectedFilterChange = new EventEmitter<MancoFilters>();

  onSelectedFilterChange() {
    this.selectedFilterChange.emit(this.selectedFilter);
  }
}
