<div class="">
  <div *ngIf="!isLoading; else loading">
    <div class="form-text">
      <div class="row p-0 g-0" *ngIf="customerInfo; else customerNotFound">
        <div class="col-xxl-9 col-12">
          <span>
            <a
              href="{{ customerInfo.icmLinkID | icmCustomerLink }}"
              target="_blank"
            >
              {{ customerInfo.customerNumber }}</a
            ></span
          >: <span>{{ customerInfo.companyName }}</span>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <span class="me-1">Actief?</span>
          <span *ngIf="customerInfo.active; else inactive"> ja</span>
          <ng-template #inactive
            ><span class="red">
              <i class="fa-solid fa-triangle-exclamation"></i>
              Nee
              <i class="fa-solid fa-triangle-exclamation"></i></span
          ></ng-template>
        </div>
      </div>
    </div>
    <ng-template #customerNotFound>
      Geen klantgegevens gevonden voor dit nummer
      {{ searchedNumber }}</ng-template
    >
  </div>
  <ng-template #loading>
    <div class="d-flex justify-content-center">
      <app-loading-wrenches></app-loading-wrenches>
    </div>
  </ng-template>
</div>
