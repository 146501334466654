import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { EmptyProfile, Profile } from '../profile/profile';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  profile: Profile = EmptyProfile();

  constructor(private authService: AuthService) {}
  ngOnInit(): void {
    if (this.isAuthenticated()) {
      this.profile = this.authService.getUserInfo();
    }
  }
  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }
  login() {
    this.authService.login();
  }
}
