<th
  [ngClass]="{
    'text-danger': !quoteLine.found
  }"
>
  {{ quoteLine.sku }}
</th>
<td>
  <img
    *ngIf="quoteLine.found"
    class="product-image"
    src="https://assets.indi.nl/images/2XS/{{ quoteLine.mainImage }}"
  />
</td>
<td
  [ngClass]="{
    'text-danger': !quoteLine.found
  }"
>
  {{ quoteLine.found ? quoteLine.name : "Niet beschikbaar" }}
</td>
<td class="text-end no-wrap">
  <div *ngIf="quoteLine.found">
    <input
      name="discount"
      size="4"
      [(ngModel)]="discount"
      type="number"
      min="0"
      (blur)="computeDiscount()"
      (change)="computeDiscount()"
    />&nbsp;%
  </div>
</td>
<td
  class="text-end"
  [ngClass]="{
    'text-danger': !quoteLine.found
  }"
>
  {{ quoteLine.quantity | currencyformatpipe : "" : 2 }}
</td>
<td class="text-end">
  <span *ngIf="quoteLine.found">
    {{ quoteLine.lineCostPrice! | currencyformatpipe }}</span
  >
</td>
<td class="text-end">
  <span *ngIf="quoteLine.found">
    {{ quoteLine.lineListPrice! | currencyformatpipe }}</span
  >
</td>
<td class="text-end">
  <span *ngIf="quoteLine.found"> {{ margin | currencyformatpipe : "" }}</span>
</td>
