<h1 class="text-center border-bottom pb-3">SCSN Klanten</h1>
<div [ngClass]="{ 'd-none': isLoading }">
  <div class="row">
    <div class="col" [ngClass]="{ 'd-none': failedToLoad }">
      <app-scsn-customers-form
        [customers]="extSCSNCustomers"
        (sendCreate)="createSCSNCustomer($event)"
        (sendUpdate)="updateSCSNCustomer($event)"
      ></app-scsn-customers-form>
    </div>
    <div class="col">
      <app-feedback (update)="returnHome()"></app-feedback>
    </div>
  </div>

  <app-scsn-customers-table
    [ngClass]="{ 'd-none': failedToLoad }"
    [extSCSNCustomers]="extSCSNCustomers"
    (sendEditSCSNCustomer)="editSCSNCustomer($event)"
    (sendEnableOrDisable)="updateSCSNCustomer($event)"
  >
  </app-scsn-customers-table>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
