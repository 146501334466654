import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TooltipPosition } from 'src/app/general-components/tooltip/tooltip.enums';

@Component({
  selector: 'app-return-update-feedback',
  templateUrl: './return-update-feedback.component.html',
  styleUrls: ['./return-update-feedback.component.scss'],
})
export class ReturnUpdateFeedbackComponent {
  @Input('success') success: boolean = false;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  x = 0;
  y = 0;
  coordinates = '';

  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
    this.x = $event.clientX;
    this.y = $event.clientY;
    this.coordinates = `${this.x},${this.y}`;
  }
}
