import { Component, OnInit } from '@angular/core';
import { PaymentTermsCustomersService } from './check-payment-terms-customers.service';
import { CustomerReportLine } from './customer-report-line';
import { ActivatedRoute } from '@angular/router';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';

@Component({
  selector: 'app-check-payment-terms',
  templateUrl: './check-payment-terms-customers.component.html',
  styleUrls: ['./check-payment-terms-customers.component.scss'],
})
export class PaymentTermsCustomersComponent implements OnInit {
  paymentTerm: string = 'Unspecified';
  customerReportLines: CustomerReportLine[] = [];

  isLoading: boolean = false;
  showFeedback: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(
    private paymentTermsCustomersService: PaymentTermsCustomersService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!params.paymentTerm) {
        this.updateFeedback(
          FeedbackType.error,
          'Er is geen paymentTerm opgegeven, ga terug naar de lijst'
        );
        return;
      }
      this.paymentTerm = decodeURIComponent(params.paymentTerm);
      this.getCustomerReportLines();
    });
  }

  updateFeedback(type: FeedbackType, message: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = true;
    this.feedbackSettings.buttonText = undefined;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  getCustomerReportLines() {
    this.isLoading = true;
    this.paymentTermsCustomersService
      .getCustomerReportLines(this.paymentTerm)
      .subscribe(
        (customerReportLines) => {
          this.customerReportLines = customerReportLines;
          this.isLoading = false;
        },
        (error) => {
          this.updateFeedback(
            FeedbackType.error,
            'We konden de klanten met "' + this.paymentTerm + '" niet ophalen.'
          );
          this.isLoading = false;
        }
      );
  }
}
