export interface FestoSpecial {
  id: number;
  sku: string;
  creationDate?: string;
  creationBy: string;
  supplier: string;
  description: string;
  vendorIC1: string;
  vendorIC2: string;
  vendorHUB: string;
  supplierSKU: string;
  purchasePrice?: number;
  supplierPrice?: number;
  salesPrice?: number;
  orderUnit: number;
  deliveryDays: number;
}

export const EmptyFestoSpecial = (): FestoSpecial => ({
  id: 1,
  sku: '',
  creationBy: '',
  supplier: 'SUPPLIER_FESTO',
  description: '',
  vendorIC1: '',
  vendorIC2: '',
  vendorHUB: '',
  supplierSKU: '',
  orderUnit: 1,
  deliveryDays: 0,
});
