import { Pipe, PipeTransform } from '@angular/core';
import { FestoSpecial } from './festo-special';

@Pipe({
  name: 'searchFestoSpecials',
})
export class SearchFestoSpecialsPipe implements PipeTransform {
  transform(items: FestoSpecial[], searchText: string): FestoSpecial[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.sku.toLowerCase().includes(searchText) ||
        it.description.toLowerCase().includes(searchText) ||
        it.supplierSKU.toLowerCase().includes(searchText) ||
        it.vendorIC1.toLowerCase().includes(searchText) ||
        it.vendorHUB!.toLowerCase().includes(searchText) ||
        it.creationBy.toLowerCase().includes(searchText)
      );
    });
  }
}
