import { Component, Input, OnInit, Output } from '@angular/core';
import { ReturnItem } from '../returnItem';
import { ReturnsService } from '../returns.service';
declare var $: any;

@Component({
  selector: 'tr[app-return-item-line]',
  templateUrl: './return-item-line.component.html',
  styleUrls: ['./return-item-line.component.scss'],
})
export class ReturnItemLineComponent {
  @Input('returnItem') returnItem!: ReturnItem;
  @Input('index') index!: number;

  updated: boolean = false;
  success: boolean = true;

  constructor(private returnsService: ReturnsService) {}

  updateReturnItem(returnItem: ReturnItem) {
    this.returnsService.setProcessed(returnItem).subscribe(
      (response) => {
        this.updated = true;
        this.success = true;
      },
      (error) => {
        console.error(error);
        this.updated = true;
        this.success = false;
      }
    );
  }
}
