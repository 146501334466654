import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeedbackSettings } from './feedback-settings';
import { FeedbackType } from './feedback-type';
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  animations: [
    trigger('fade', [
      state('false', style({ opacity: 1 })),
      state('true', style({ opacity: 0 })),
      transition('false => true', animate('2000ms ease-out')),
    ]),
  ],
})
export class FeedbackComponent {
  @Input('settings') settings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.succes,
    message: '',
    closeButton: false,
  };

  @Output() settingsChange = new EventEmitter<FeedbackSettings>();

  @Output('update') update: EventEmitter<string> = new EventEmitter();

  public fadeOutStart = false;

  showFeedback(
    identifier: string,
    type: FeedbackType,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ): void {
    this.settings = {
      identifier: identifier,
      type: type,
      show: true,
      message: message,
      buttonText: buttonText,
      closeButton: closeButton,
    };
  }

  hide() {
    this.settings.show = false;
    this.settingsChange.emit(this.settings);
  }

  feedbackType() {
    return FeedbackType;
  }

  sendUpdate() {
    this.update.emit(this.settings.identifier);
  }

  fadeOut() {
    console.log('auto hide!');
    setTimeout(() => {
      this.fadeOutStart = true;
    }, 0);
  }

  animationDone($event: AnimationEvent) {
    if ($event.toState) {
      this.hide();
      this.fadeOutStart = false;
    }
  }
}
