<div class="container">
  <div class="loading-dot">
    <i class="fa-solid fa-wrench fa-2xs"></i>
  </div>
  <div class="loading-dot">
    <i class="fa-solid fa-wrench fa-2xs"></i>
  </div>
  <div class="loading-dot">
    <i class="fa-solid fa-wrench fa-2xs"></i>
  </div>
  <div class="loading-dot">
    <i class="fa-solid fa-wrench fa-2xs"></i>
  </div>
  <div class="loading-dot">
    <i class="fa-solid fa-wrench fa-2xs"></i>
  </div>
</div>
