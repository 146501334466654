<h1 class="text-center border-bottom pb-3">Kramp verzend adressen</h1>

<div [ngClass]="{ 'd-none': isLoading }">
  <div class="row" *ngIf="!feedbackSettings.show">
    <div class="col-6">
      <app-kramp-shipping-address-form
        [shippingAddresses]="shippingAddresses"
        (updateShippingAddress)="updateShippingAddress($event)"
        (createShippingAddress)="createShippingAddress($event)"
        (cancelEdit)="cancelEdit()"
      ></app-kramp-shipping-address-form>
    </div>
    <div class="col-6">
      <div
        class="text-center border-0 d-flex flex-column justify-content-center h-100"
        *ngIf="deleteCandidateShippingAddress != undefined"
      >
        <div class="align-self-center">
          <div class="mb-3">
            <H4 class="mb-2"
              >Weet je zeker dat je dit verzendadres wilt verwijderen?</H4
            >
            <p>
              <strong>{{ deleteCandidateShippingAddress.name }} </strong>
            </p>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <div class="me-5">
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="clearDeleteCandidate()"
              >
                Annuleren
              </button>
            </div>
            <div>
              <button
                type="button"
                (click)="deleteShippingAddress(deleteCandidateShippingAddress)"
                class="btn btn-outline-danger"
              >
                Verwijderen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="d-flex justify-content-center my-5">
      <app-feedback
        [(settings)]="feedbackSettings"
        (update)="handleFeedbackOutput($event)"
      ></app-feedback>
    </div>
    <app-kramp-shipping-addresses-table
      [shippingAddresses]="shippingAddresses"
      (editShippingAddress)="editShippingAddress($event)"
      (deleteCandidate)="setDeleteCandidate($event)"
    ></app-kramp-shipping-addresses-table>
  </div>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
