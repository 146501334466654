import { Component, Input } from '@angular/core';
import { CustomerInfo } from '../../customer-info.model';

@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss'],
})
export class CustomerInformationComponent {
  @Input() customerInfo?: CustomerInfo;
  @Input() searchedNumber!: string;

  isLoading: boolean = false;

  setIsLoading(value: boolean): void {
    this.isLoading = value;
  }
}
