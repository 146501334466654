<table class="table table-hover prices" *ngIf="quoteLines.length > 0">
  <thead>
    <tr>
      <th scope="col" class="width-10">SKU</th>
      <th scope="col" class="width-5">Plaatje</th>
      <th scope="col" class="width-auto">Naam</th>
      <th scope="col" class="width-10">Discount</th>
      <th scope="col" class="width-10">Aantal</th>
      <th scope="col" class="width-10">Kostprijs</th>
      <th scope="col" class="width-10">Shop-prijs</th>
      <th scope="col" class="width-10">Marge %</th>
    </tr>
  </thead>
  <tbody>
    <tr
      app-quote-line
      [(quoteLine)]="quoteLines[i]"
      (quoteLineChange)="onQuoteLineChanged()"
      [autoFillDiscount]="autoFillDiscount"
      *ngFor="let quoteLine of quoteLines; let i = index"
    ></tr>
    <tr class="total">
      <td colspan="3"></td>
      <td class="text-end no-wrap">
        <input
          name="discountTotal"
          size="4"
          type="number"
          [(ngModel)]="autoFillDiscount"
          min="0"
          value="0"
        />&nbsp;%
      </td>
      <th>Totaal</th>
      <td class="text-end">
        {{ totalCostPrice | currencyformatpipe }}
      </td>
      <td class="text-end">
        {{ totalListPrice | currencyformatpipe }}
      </td>
      <td class="text-end">
        {{ totalMargin | currencyformatpipe : "" }}
      </td>
    </tr>
    <tr>
      <td colspan="4"></td>
      <th>Marge</th>
      <td class="text-end no-wrap">
        <input
          name="totalMarge"
          size="4"
          type="number"
          min="0"
          [(ngModel)]="totalSalesMargin"
          (change)="computeTotals()"
        />&nbsp;%
      </td>
      <td colspan="2"></td>
    </tr>
    <tr>
      <td colspan="4"></td>
      <th>Totale verkoop-prijs</th>
      <td class="text-end">
        {{ totalSalesPrice | currencyformatpipe }}
      </td>
      <td colspan="2"></td>
    </tr>
  </tbody>
</table>
