import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CONNECTION_STATUS_CHECK_URL as CONNECTION_STATUS_CHECK_URL } from 'src/app/injection-tokens';
import { Status } from '../model/status';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusService {
  readonly networkTimeoutTime: number = 12500; // time until request manually times out

  constructor(
    @Inject(CONNECTION_STATUS_CHECK_URL) readonly serviceURL: string,
    private http: HttpClient
  ) {}

  getConnectionStatusFromQuarkus(): Observable<Status> {
    return this.http.get<Status>(this.serviceURL);
  }

  isConnectionStatusUp(): Observable<boolean> {
    return this.getConnectionStatusFromQuarkus()!.pipe(
      timeout(this.networkTimeoutTime),
      map((response) => response.status == 'UP'),
      catchError((error) => {
        console.error('Network or CORS error occurred:', error);
        return of(false);
      })
    );
  }
}
