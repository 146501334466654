import {
  Component,
  ElementRef,
  Input,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
  EventEmitter,
} from '@angular/core';
import { KrampShippingAddress } from '../kramp-shipping-address';

@Component({
  selector: 'app-kramp-shipping-addresses-table',
  templateUrl: './kramp-shipping-addresses-table.component.html',
  styleUrls: ['./kramp-shipping-addresses-table.component.scss'],
})
export class KrampShippingAddressesTableComponent {
  @ViewChildren('sortIcons') sortIcons!: QueryList<ElementRef>;
  @Input('shippingAddresses') shippingAddresses: KrampShippingAddress[] = [];

  @Output('editShippingAddress')
  editShippingAddress: EventEmitter<KrampShippingAddress> = new EventEmitter();

  // @Output('deleteCandidate')
  // deleteCandidate: EventEmitter<KrampShippingAddress> = new EventEmitter();

  @Output('deleteCandidate')
  deleteCandidate: EventEmitter<KrampShippingAddress> = new EventEmitter();

  constructor(private renderer: Renderer2) {}

  searchText: string = '';
  sortByIdAsc: boolean = false;
  sortByNameAsc: boolean = false;
  sortByAddressAsc: boolean = false;
  sortByZipCodeAsc: boolean = false;
  sortByCityAsc: boolean = false;
  sortByKrampShipToNoAsc: boolean = false;
  sortByHasNightShippingAsc: boolean = false;
  sortByActiveAsc: boolean = false;
  sortByInTransitAsc: boolean = false;
  sortByLastModifiedAsc: boolean = false;
  sortByModifiedByAsc: boolean = false;

  coordinates = '';
  page: number = 1;
  selectedIndex: number = -1;

  setDeleteCandidate(shippingAddress: KrampShippingAddress): void {
    this.deleteCandidate.emit(shippingAddress);
  }

  setEditShippingAddress(
    shippingAddress: KrampShippingAddress,
    index: number
  ): void {
    this.editShippingAddress.emit(shippingAddress);
    this.selectedIndex = index;
  }

  isGermanZipCode(shippingAddress: KrampShippingAddress): boolean {
    return shippingAddress.zipCode === '48455 BAD'; //this is the one german address we have, and it messes with the regex so we've decided to disable it.
  }

  resetSelectedIndex(): void {
    this.selectedIndex = -1;
  }

  sortByField(field: string, ascendingOrder: boolean) {
    switch (field) {
      case 'id': {
        this.sortByIdAsc = ascendingOrder;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.name < shippingAddress2.name) return -1;
              if (shippingAddress1.name > shippingAddress2.name) return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.name < shippingAddress2.name) return 1;
              if (shippingAddress1.name > shippingAddress2.name) return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'name': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = ascendingOrder;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.name < shippingAddress2.name) return -1;
              if (shippingAddress1.name > shippingAddress2.name) return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.name < shippingAddress2.name) return 1;
              if (shippingAddress1.name > shippingAddress2.name) return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'address': {
        //TODO: this can be better but I dont care right now, sorry
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = ascendingOrder;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.address < shippingAddress2.address)
                return -1;
              if (shippingAddress1.address > shippingAddress2.address) return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.address < shippingAddress2.address) return 1;
              if (shippingAddress1.address > shippingAddress2.address)
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'zipCode': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = ascendingOrder;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.zipCode < shippingAddress2.zipCode)
                return -1;
              if (shippingAddress1.zipCode > shippingAddress2.zipCode) return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.zipCode < shippingAddress2.zipCode) return 1;
              if (shippingAddress1.zipCode > shippingAddress2.zipCode)
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'city': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = ascendingOrder;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.city < shippingAddress2.city) return -1;
              if (shippingAddress1.city > shippingAddress2.city) return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.city < shippingAddress2.city) return 1;
              if (shippingAddress1.city > shippingAddress2.city) return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'shipToNo': {
        //TODO: this can be better but I dont care right now, sorry
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = ascendingOrder;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.shipToNo! < shippingAddress2.shipToNo!)
                return -1;
              if (shippingAddress1.shipToNo! > shippingAddress2.shipToNo!)
                return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.shipToNo! < shippingAddress2.shipToNo!)
                return 1;
              if (shippingAddress1.shipToNo! > shippingAddress2.shipToNo!)
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'hasNightShipping': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = ascendingOrder;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;
        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (
                shippingAddress1.hasNightShipping <
                shippingAddress2.hasNightShipping
              )
                return -1;
              if (
                shippingAddress1.hasNightShipping >
                shippingAddress2.hasNightShipping
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (
                shippingAddress1.hasNightShipping <
                shippingAddress2.hasNightShipping
              )
                return 1;
              if (
                shippingAddress1.hasNightShipping >
                shippingAddress2.hasNightShipping
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'active': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = ascendingOrder;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;
        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.active < shippingAddress2.active) return -1;
              if (shippingAddress1.active > shippingAddress2.active) return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.active < shippingAddress2.active) return 1;
              if (shippingAddress1.active > shippingAddress2.active) return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'inTransit': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = ascendingOrder;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = false;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.inTransit! < shippingAddress2.inTransit!)
                return -1;
              if (shippingAddress1.inTransit! > shippingAddress2.inTransit!)
                return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.inTransit! < shippingAddress2.inTransit!)
                return 1;
              if (shippingAddress1.inTransit! > shippingAddress2.inTransit!)
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'lastModified': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = ascendingOrder;
        this.sortByModifiedByAsc = false;
        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (
                shippingAddress1.lastModified! < shippingAddress2.lastModified!
              )
                return -1;
              if (
                shippingAddress1.lastModified! > shippingAddress2.lastModified!
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (
                shippingAddress1.lastModified! < shippingAddress2.lastModified!
              )
                return 1;
              if (
                shippingAddress1.lastModified! > shippingAddress2.lastModified!
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'modifiedBy': {
        this.sortByIdAsc = false;
        this.sortByNameAsc = false;
        this.sortByAddressAsc = false;
        this.sortByZipCodeAsc = false;
        this.sortByCityAsc = false;
        this.sortByKrampShipToNoAsc = false;
        this.sortByHasNightShippingAsc = false;
        this.sortByActiveAsc = false;
        this.sortByInTransitAsc = false;
        this.sortByLastModifiedAsc = false;
        this.sortByModifiedByAsc = ascendingOrder;

        if (ascendingOrder) {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.modifiedBy! < shippingAddress2.modifiedBy!)
                return -1;
              if (shippingAddress1.modifiedBy! > shippingAddress2.modifiedBy!)
                return 1;
              return 0;
            }
          );
        } else {
          this.shippingAddresses = this.shippingAddresses.sort(
            (shippingAddress1, shippingAddress2) => {
              if (shippingAddress1.modifiedBy! < shippingAddress2.modifiedBy!)
                return 1;
              if (shippingAddress1.modifiedBy! > shippingAddress2.modifiedBy!)
                return -1;
              return 0;
            }
          );
        }
        break;
      }

      default: {
        break;
      }
    }
    this.updateSortingHighlight(`${field}${ascendingOrder ? 'Asc' : 'Desc'}`);
  }

  updateSortingHighlight(highlightElement: string): void {
    this.sortIcons.forEach((element: ElementRef) => {
      if (element.nativeElement.id == highlightElement) {
        this.renderer.addClass(element.nativeElement, 'highlight');
      } else {
        this.renderer.removeClass(element.nativeElement, 'highlight');
      }
    });
  }
}
