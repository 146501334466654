<p class="text-warning" *ngIf="didSearch && sosCustomers!.length == 0">
  We hebben geen orders gevonden met de door u gegeven gegevens.
</p>
<p *ngIf="sosCustomers!.length != 0">
  Stuur een
  <a [href]="getMailToLink()"
    >email
    {{
      sosCustomers!.length == 1
        ? "aan deze klant"
        : "aan al deze " + sosCustomers!.length + " klanten"
    }}</a
  >
  door op de link te klikken.
</p>
<table class="table table-hover" *ngIf="sosCustomers!.length > 0">
  <thead>
    <tr>
      <th>Klantgegevens</th>
      <th>Order(s)</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let sosCustomer of sosCustomers">
      <td>
        <div class="row">
          <div class="col-6">
            <div>
              <a
                href="{{ sosCustomer.customerId | omsCustomerLink }}"
                target="_blank"
                >{{ sosCustomer.shopCustomerNumber }}</a
              >
            </div>
            <div>{{ sosCustomer.companyName }}</div>
          </div>
          <div class="col-6">
            <div>
              <div>
                <div>
                  {{ sosCustomer.firstName }} {{ sosCustomer.lastName }}
                </div>
              </div>
              <div>
                <a
                  href="mailto:{{ sosCustomer.emailAddress }}"
                  target="_blank"
                  >{{ sosCustomer.emailAddress }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </td>

      <td>
        <ul class="list-group list-group-flush">
          <li>
            <div class="row">
              <div class="col-4 fw-semibold">Datum</div>
              <div class="col-4 fw-semibold">leveranciers ordernummer</div>
              <div class="col-4 fw-semibold">Indi ordernummer</div>
            </div>
          </li>
          <li *ngFor="let order of sosCustomer?.orders">
            <div class="row">
              <div class="col-4">
                {{ order.indiOrderDateTime | cleanDate : "date-time" }}
              </div>
              <div class="col-4">{{ order.supplierOrderNumber }}</div>
              <div class="col-4">
                <a href="{{ order.orderId | omsOrderLink }}" target="_blank">{{
                  order.indiOrderNumber
                }}</a>
              </div>
            </div>
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
