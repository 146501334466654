import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  EmptyKrampShippingAddress,
  KrampShippingAddress,
} from '../kramp-shipping-address';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-kramp-shipping-address-form',
  templateUrl: './kramp-shipping-address-form.component.html',
  styleUrls: ['./kramp-shipping-address-form.component.scss'],
})
export class KrampShippingAddressFormComponent {
  @ViewChild('shippingAddressCustomerId') shippingAddressCustomerId!: NgModel;
  @ViewChild('shippingAddressName') shippingAddressName!: NgModel;
  @ViewChild('shippingAddressAddress') shippingAddressAddress!: NgModel;
  @ViewChild('shippingAddressZipCode') shippingAddressZipCode!: NgModel;
  @ViewChild('shippingAddressCity') shippingAddressCity!: NgModel;
  @ViewChild('shippingAddresShipToNo') shippingAddresShipToNo!: NgModel;

  @Input('shippingAddresses') shippingAddresses!: KrampShippingAddress[];

  @Output('createShippingAddress')
  createShippingAddress: EventEmitter<KrampShippingAddress> =
    new EventEmitter();

  @Output('updateShippingAddress')
  updateShippingAddress: EventEmitter<KrampShippingAddress> =
    new EventEmitter();

  @Output('cancelEdit')
  cancelEdit: EventEmitter<any> = new EventEmitter();

  createMode: boolean = true;
  workingShippingAddress: KrampShippingAddress = EmptyKrampShippingAddress();

  sendUpdateShippingAddress(shippingAddress: KrampShippingAddress): void {
    this.updateShippingAddress.emit(shippingAddress);
  }

  sendCreateShippingAddress(shippingAddress: KrampShippingAddress): void {
    this.createShippingAddress.emit(shippingAddress);
  }

  editShippingAddress(shippingAddress: KrampShippingAddress): void {
    this.workingShippingAddress = Object.assign({}, shippingAddress);
    this.createMode = false;
  }

  cancel() {
    if (!this.createMode) {
      this.cancelEdit.emit();
    }
  }

  resetInputFields(): void {
    this.shippingAddressCustomerId.reset();
    this.shippingAddressName.reset();
    this.shippingAddressAddress.reset();
    this.shippingAddressZipCode.reset();
    this.shippingAddressCity.reset();
    this.shippingAddresShipToNo.reset();
    this.workingShippingAddress = EmptyKrampShippingAddress();
    this.createMode = true;
  }

  isFormValid(): boolean {
    return (
      this.isCustomerIdValid() && //
      this.shippingAddressName &&
      this.shippingAddressAddress.valid! && //
      this.shippingAddressAddress &&
      this.shippingAddressAddress.valid! && //
      this.shippingAddressZipCode &&
      this.shippingAddressZipCode.valid! && //
      this.shippingAddressCity &&
      this.shippingAddressCity.valid! && //
      this.isShipToNoValid()
    );
  }

  isShipToNoValid(): boolean {
    return (
      this.shippingAddresShipToNo &&
      this.shippingAddresShipToNo.valid! &&
      this.isShipToNoUnique()
    );
  }

  isShipToNoUnique(): boolean {
    let foundShippingAddress: KrampShippingAddress | undefined =
      this.shippingAddresses.find((s) => {
        if (this.createMode) {
          return s.shipToNo! === this.workingShippingAddress.shipToNo!;
        } else {
          return (
            s.shipToNo! === this.workingShippingAddress.shipToNo! &&
            s.id != this.workingShippingAddress.id
          );
        }
      });
    if (foundShippingAddress) {
    }
    return foundShippingAddress == undefined;
  }

  isCustomerIdValid(): boolean {
    return (
      this.shippingAddressCustomerId &&
      this.shippingAddressCustomerId.valid! &&
      this.isCustomeridUnique()
    );
  }

  isCustomeridUnique(): boolean {
    let foundShippingAddress: KrampShippingAddress | undefined =
      this.shippingAddresses.find((s) => {
        if (this.createMode) {
          return s.customerId! === this.workingShippingAddress.customerId!;
        } else {
          return (
            s.customerId! === this.workingShippingAddress.customerId! &&
            s.id != this.workingShippingAddress.id
          );
        }
      });
    return foundShippingAddress == undefined;
  }
}
