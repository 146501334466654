<app-feedback
  [ngClass]="{ 'd-none': !isError }"
  [(settings)]="feedbackSettings"
></app-feedback>

<span *ngIf="processedFiles.length > 0">
  {{
    processedFiles.length > 1
      ? "De laatste " + processedFiles.length + " verwerkte bestanden zijn:"
      : "Het laatste verwerkte bestand is:"
  }}</span
>

<ul class="my-2">
  <li *ngFor="let file of processedFiles">
    <div class="row g-0">
      <div class="col-8">{{ file.filename }}</div>
      <div class="col">
        ({{ file.timestamp | utcDate : "yyyy-MM-dd HH:mm:ss" }})
      </div>
    </div>
  </li>
</ul>
