import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { QuoteLine } from '../quote-line';

@Component({
  selector: 'tr[app-quote-line]',
  templateUrl: './quote-line.component.html',
  styleUrls: ['../quote-table/quote-table.component.scss'],
})
export class QuoteLineComponent implements OnInit, OnChanges {
  @Input('quoteLine') quoteLine!: QuoteLine;
  @Output() quoteLineChange = new EventEmitter<QuoteLine>();

  @Input('autoFillDiscount') discount: number = 0;
  margin: number = 0;

  ngOnInit(): void {
    if (this.quoteLine.found) {
      this.quoteLine.lineCostPrice =
        this.quoteLine.quantity * this.quoteLine.unitCostPrice!;
      this.computeDiscount();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.computeDiscount();
  }

  computeDiscount() {
    // Use setTimeout to make Angular detection sleepy
    setTimeout(() => this.computeDiscountImpl());
  }

  // WARN: Only not private to allow testing
  computeDiscountImpl() {
    if (!this.quoteLine.found) {
      return;
    }

    const originalTotalListPrice =
      this.quoteLine.quantity * this.quoteLine.unitListPrice!;
    const discountAmount = originalTotalListPrice * (this.discount / 100);

    this.quoteLine.lineListPrice = originalTotalListPrice - discountAmount;
    this.margin =
      (this.quoteLine.lineListPrice! - this.quoteLine.lineCostPrice!) /
      (this.quoteLine.lineListPrice! / 100);

    this.quoteLineChange.emit(this.quoteLine);
  }
}
