import { Inject, Pipe, PipeTransform } from '@angular/core';
import { BASE_FRESHDESK_URL } from 'src/app/injection-tokens';

@Pipe({
  name: 'freshdeskTicketLink',
})
export class FreshdeskTicketLinkPipe implements PipeTransform {
  constructor(@Inject(BASE_FRESHDESK_URL) readonly baseFreshdeskUrl: string) {}

  transform(ticketId: string, ...args: unknown[]): unknown {
    return `${this.baseFreshdeskUrl}/a/tickets/${ticketId}`;
  }
}
