import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanDate',
})
export class CleanDatePipe implements PipeTransform {
  transform(date: string, ...args: string[]): unknown {
    switch (args[0]) {
      case 'date':
        return date.substring(0, 10);
      case 'date-time':
        return date.substring(0, 10) + ' ' + date.substring(11, 19);
      case 'full':
        return date.substring(0, 10) + ' ' + date.substring(11);
      default:
        return date;
    }
  }
}
