<div *ngIf="shippingAddresses.length > 0">
  <div class="d-flex justify-content-end mb-3">
    <div class="searchbar">
      <input
        class="search_input"
        type="text"
        [(ngModel)]="searchText"
        placeholder="Zoeken op naam of nummer..."
      />
      <div class="search_icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          class="width-5 sort"
          (click)="sortByField('id', !sortByIdAsc)"
        >
          ID

          <div
            #sortIcons
            id="idAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="idDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-15 sort"
          (click)="sortByField('name', !sortByNameAsc)"
        >
          Naam

          <div
            #sortIcons
            id="nameAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="nameDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('address', !sortByAddressAsc)"
        >
          Adres
          <div
            #sortIcons
            id="addressAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="addressDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('zipCode', !sortByZipCodeAsc)"
        >
          Postcode
          <div
            #sortIcons
            id="zipCodeAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="zipCodeDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('city', !sortByCityAsc)"
        >
          Stad
          <div
            #sortIcons
            id="cityAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="cityDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('shipToNo', !sortByKrampShipToNoAsc)"
        >
          <span class="d-xxl-none">Shiptono</span>
          <span class="d-none d-xxl-inline">Kramp Ship To No</span>
          <div
            #sortIcons
            id="shipToNoAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="shipToNoDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('hasNightShipping', !sortByHasNightShippingAsc)"
        >
          <span class="d-xxl-none">Nox?</span>
          <span class="d-none d-xxl-inline">Nachtbezorging?</span>
          <div
            #sortIcons
            id="hasNightShippingAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="hasNightShippingDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('active', !sortByActiveAsc)"
        >
          <span class="d-xxl-none">Actief?</span>
          <span class="d-none d-xxl-inline">Actief?</span>
          <div
            #sortIcons
            id="activeAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="activeDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('inTransit', !sortByInTransitAsc)"
        >
          <span class="d-xxl-none">Sync?</span>
          <span class="d-none d-xxl-inline">Gesynced?</span>
          <div
            #sortIcons
            id="inTransitAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="inTransitDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('lastModified', !sortByLastModifiedAsc)"
        >
          <span class="d-xxl-none">Datum</span>
          <span class="d-none d-xxl-inline">Laatst aangepast</span>
          <div
            #sortIcons
            id="lastModifiedAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="lastModifiedDesc"
            class="fa-solid fa-sort-down sort-icon highlight"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('modifiedBy', !sortByModifiedByAsc)"
        >
          <span class="d-xxl-none">Editor</span>
          <span class="d-none d-xxl-inline">Aangepast door</span>
          <div
            #sortIcons
            id="modifiedByAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="modifiedByDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th scope="col" class="width-10">acties</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let shippingAddress of shippingAddresses
            | searchKrampShippingAddresses : searchText
            | paginate : { itemsPerPage: 50, currentPage: page };
          index as i
        "
        [ngClass]="{ 'fw-bolder primary-color': i === selectedIndex }"
      >
        <th scope="row">{{ shippingAddress.customerId }}</th>
        <td>{{ shippingAddress.name }}</td>
        <td>{{ shippingAddress.address }}</td>
        <td>{{ shippingAddress.zipCode }}</td>
        <td>{{ shippingAddress.city }}</td>
        <td>{{ shippingAddress.shipToNo }}</td>
        <td>{{ shippingAddress.hasNightShipping | yesNo : "nl" }}</td>
        <td>{{ shippingAddress.active | yesNo : "nl" }}</td>
        <td>
          {{ shippingAddress.inTransit! | yesNo : "nl" }}
        </td>
        <td>
          {{ shippingAddress.lastModified! | cleanDate : "date-time" }}
        </td>
        <td>{{ shippingAddress.modifiedBy }}</td>
        <td>
          <div class="d-flex flex-row p-0">
            <div>
              <div>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm me-3"
                  (click)="setEditShippingAddress(shippingAddress, i)"
                  [disabled]="isGermanZipCode(shippingAddress)"
                >
                  <span class="d-xxl-none"
                    ><i class="fa-solid fa-pencil"></i
                  ></span>
                  <span class="d-none d-xxl-inline">
                    {{
                      isGermanZipCode(shippingAddress)
                        ? "Niet Aanpassen"
                        : "Aanpassen"
                    }}</span
                  >
                </button>
              </div>
            </div>
            <div>
              <button
                class="btn btn-outline-danger btn-sm"
                (click)="setDeleteCandidate(shippingAddress)"
              >
                <span class="d-xxl-none"
                  ><i class="fa-solid fa-trash-can"></i
                ></span>
                <span class="d-none d-xxl-inline">Verwijderen</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls
    (pageChange)="page = $event"
    previousLabel="Vorige"
    nextLabel="Volgende"
    [autoHide]="true"
  ></pagination-controls>
</div>
