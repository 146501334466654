import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PRICE_CHECKER_SERVICE_URL } from 'src/app/injection-tokens';
import { ProductInfo } from './product-info';
import { ProductInfoHolder } from './product-info-holder';

@Injectable({
  providedIn: 'root',
})
export class PriceCheckerService {
  constructor(
    @Inject(PRICE_CHECKER_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getPrices(skus: string[]): Observable<ProductInfoHolder> {
    return this.http.post<ProductInfoHolder>(this.serviceURL, {
      skus: skus,
    });
  }
}
