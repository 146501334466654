import { Inject, Injectable } from '@angular/core';
import { DispatchFailure } from './dispatch-failure';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DISPATCH_FAILURE_SERVICE_URL } from 'src/app/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class DispatchFailuresService {
  constructor(
    @Inject(DISPATCH_FAILURE_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getDispatchFailures(): Observable<DispatchFailure[]> {
    return this.http.get<DispatchFailure[]>(this.serviceURL);
  }
}
