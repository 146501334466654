<div class="radio-group d-flex flex-row">
  <label *ngFor="let filter of possibleFilters" class="me-3">
    <input
      type="radio"
      class="form-check-input"
      name="options"
      [value]="filter"
      [(ngModel)]="selectedFilter"
      (change)="onSelectedFilterChange()"
    />
    {{ filter }}
  </label>
</div>
