import { Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { PriceCheckerService } from './price-checker.service';
import { ProductInfo } from './product-info';
import { QuoteLine } from './quote-line';
import { RequestedSKUAndQuatity } from './requested-skus-and-quatity';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { ProductInfoHolder } from './product-info-holder';

@Component({
  selector: 'app-price-checker',
  templateUrl: './price-checker.component.html',
  styleUrls: ['./price-checker.component.scss'],
})
export class PriceCheckerComponent {
  @ViewChild('articleInfoInput') articleInfoInput!: NgModel;

  articleInfoText: string = '';
  requestedSKUsAndQuatities: RequestedSKUAndQuatity[] = [];
  quoteLines: QuoteLine[] = [];
  totalCostPrice: number = 0;

  // State machine
  isLoading: boolean = false;
  hasSearched: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };
  constructor(private service: PriceCheckerService) {}

  updateFeedback(
    identifier: string,
    type: FeedbackType,
    show: boolean,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ) {
    this.feedbackSettings.identifier = identifier;
    this.feedbackSettings.type = type;
    this.feedbackSettings.show = show;
    this.feedbackSettings.message = message;
    (this.feedbackSettings.buttonText = buttonText),
      (this.feedbackSettings.closeButton = closeButton);
  }

  getPrices() {
    this.isLoading = true;
    this.requestedSKUsAndQuatities = this.toSKUs(this.articleInfoText);
    this.service
      .getPrices(this.toSkus(this.requestedSKUsAndQuatities)) //
      .subscribe(
        (productInfoHolder: ProductInfoHolder) => {
          this.quoteLines = this.transformServiceData(
            new Map<string, ProductInfo>(
              Object.entries(productInfoHolder.productInfos)
            )
          );

          this.isLoading = false;
          this.hasSearched = true;
        },
        (error) => {
          this.updateFeedback(
            '',
            FeedbackType.error,
            true,
            'Er is *iets* fout gegaan bij het ophalen van de product informatie',
            undefined,
            true
          );
          this.isLoading = false;
          this.hasSearched = false;
        }
      );
  }

  newQuote() {
    this.hasSearched = false;
    this.requestedSKUsAndQuatities = [];
    this.quoteLines = [];
  }

  private toSkus(requestedSKUsAndQuatities: RequestedSKUAndQuatity[]) {
    return requestedSKUsAndQuatities.map(
      (requestedSKUAndQuatity) => requestedSKUAndQuatity.sku
    );
  }

  transformServiceData(productInfoMap: Map<string, ProductInfo>): QuoteLine[] {
    let quoteLines: QuoteLine[] = [];

    this.totalCostPrice = 0;
    this.requestedSKUsAndQuatities.forEach((requestedSKUAndQuatity) => {
      const productInfo = productInfoMap.get(requestedSKUAndQuatity.sku);

      const found = productInfo !== undefined;

      if (found) {
        this.totalCostPrice +=
          requestedSKUAndQuatity.quantity * productInfo.unitCostPrice;
      }

      quoteLines.push({
        sku: requestedSKUAndQuatity.sku,
        quantity: requestedSKUAndQuatity.quantity,
        found: found,
        name: !found ? undefined : productInfo.name,
        mainImage: !found ? undefined : productInfo.mainImage,
        unitCostPrice: !found ? undefined : productInfo.unitCostPrice,
        unitListPrice: !found ? undefined : productInfo.unitListPrice,
      });
    });

    return quoteLines;
  }

  toSKUs(articleInfoText: string): RequestedSKUAndQuatity[] {
    const skuMap: RequestedSKUAndQuatity[] = [];
    articleInfoText.split(/[\r\n]+/).forEach((line) => {
      const trimmedLine = line.trim();
      const [key, value] = trimmedLine.split(';').map((part) => part.trim());
      if (key != '') {
        skuMap.push({ sku: key, quantity: parseFloat(value) });
      }
    });

    return skuMap;
  }
}
