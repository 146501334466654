import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  EmptyKrampShippingAddress,
  KrampShippingAddress,
} from './kramp-shipping-address';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { KrampShippingAddressService } from './kramp-shipping-address.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { Profile } from 'src/app/general-components/profile/profile';
import { KrampShippingAddressesTableComponent } from './kramp-shipping-addresses-table/kramp-shipping-addresses-table.component';
import { KrampShippingAddressFormComponent } from './kramp-shipping-address-form/kramp-shipping-address-form.component';

@Component({
  selector: 'app-kramp-shipping-addresses',
  templateUrl: './kramp-shipping-addresses.component.html',
  styleUrls: ['./kramp-shipping-addresses.component.scss'],
})
export class KrampShippingAddressesComponent implements OnInit {
  @ViewChild(KrampShippingAddressesTableComponent)
  table!: KrampShippingAddressesTableComponent;
  @ViewChild(KrampShippingAddressFormComponent)
  form!: KrampShippingAddressFormComponent;

  shippingAddresses: KrampShippingAddress[] = [];
  selectedShippingAddress: KrampShippingAddress;

  profile: Profile | undefined;

  deleteCandidateShippingAddress?: KrampShippingAddress;
  isLoading: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };
  ShippingAddressCRUDFeedbackIdentifier = 'shippingAddressfeedback';
  generalFeedbackIdentifier = 'generalFeedback';

  page: number = 1;
  constructor(
    private krampShippingAddressService: KrampShippingAddressService,
    private authService: AuthService,
    private router: Router
  ) {
    this.selectedShippingAddress = EmptyKrampShippingAddress();
  }

  ngOnInit(): void {
    this.getKrampShippingAddresses();
    this.profile = this.authService.getUserInfo();
  }

  getKrampShippingAddresses(): void {
    this.isLoading = true;

    this.krampShippingAddressService.getKrampShippingAddresses().subscribe(
      (addresses) => {
        this.shippingAddresses = addresses;
        this.isLoading = false;
        this.shippingAddresses.sort((a, b) =>
          a.lastModified! < b.lastModified! ? 1 : -1
        );
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is iets fout gegaan bij het ophalen van de verzendadressen',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  editShippingAddress(shippingAddress: KrampShippingAddress): void {
    this.selectedShippingAddress = shippingAddress;
    this.form.editShippingAddress(shippingAddress);
    this.feedbackSettings.show = false;
  }

  createShippingAddress(shippingAddress: KrampShippingAddress): void {
    shippingAddress.modifiedBy =
      this.profile?.firstName + ' ' + this.profile?.lastName;

    this.krampShippingAddressService
      .createKrampShippingAddress(shippingAddress)
      .subscribe(
        (shippingAddress) => {
          this.addToShippingAddresses(shippingAddress);
          this.updateFeedback(
            this.ShippingAddressCRUDFeedbackIdentifier,
            FeedbackType.succes,
            true,
            `Het verzendadres voor ${shippingAddress.name} is aangemaakt!`,
            undefined,
            true
          );
        },
        (error) => {
          console.error(error);
          this.updateFeedback(
            this.ShippingAddressCRUDFeedbackIdentifier,
            FeedbackType.error,
            true,
            'Er is *iets* fout gegaan bij het aanmaken van het verzendadres',
            undefined,
            true
          );
        }
      );

    this.selectedShippingAddress = EmptyKrampShippingAddress();
    this.form.resetInputFields();
  }

  updateShippingAddress(shippingAddress: KrampShippingAddress): void {
    shippingAddress.modifiedBy =
      this.profile?.firstName + ' ' + this.profile?.lastName;
    this.krampShippingAddressService
      .updateKrampShippingAddress(shippingAddress)
      .subscribe(
        (shippingAddress) => {
          this.updateFeedback(
            this.ShippingAddressCRUDFeedbackIdentifier,
            FeedbackType.succes,
            true,
            `Het verzendadres voor ${shippingAddress.name} is aangepast!`,
            undefined,
            true
          );
          this.updateShippingAddressInList(shippingAddress);
        },
        (error) => {
          console.error(error);
          this.updateFeedback(
            this.ShippingAddressCRUDFeedbackIdentifier,
            FeedbackType.error,
            true,
            'Er is *iets* fout gegaan bij het aanpassen van het verzendadres',
            undefined,
            true
          );
        }
      );
    this.selectedShippingAddress = EmptyKrampShippingAddress();
    this.form.resetInputFields();
    this.table.resetSelectedIndex();
  }

  updateShippingAddressInList(shippingAddress: KrampShippingAddress): void {
    let foundShippingAddress: KrampShippingAddress | undefined =
      this.shippingAddresses.find((s) => {
        return s.id == shippingAddress.id;
      });
    if (foundShippingAddress) {
      //yes this is ugly, cant do this with Object.assign, cause then the ui wont know the array changed,... cant force update the array cause typescript hates its

      foundShippingAddress.customerId = shippingAddress.customerId;
      foundShippingAddress.name = shippingAddress.name;
      foundShippingAddress.address = shippingAddress.address;
      foundShippingAddress.zipCode = shippingAddress.zipCode;
      foundShippingAddress.city = shippingAddress.city;
      foundShippingAddress.shipToNo = shippingAddress.shipToNo;
      foundShippingAddress.hasNightShipping = shippingAddress.hasNightShipping;
      foundShippingAddress.active = shippingAddress.active;
      foundShippingAddress.lastModified = shippingAddress.lastModified;
      foundShippingAddress.modifiedBy = shippingAddress.modifiedBy;
      foundShippingAddress.inTransit = shippingAddress.inTransit;
    }
  }

  deleteShippingAddress(shippingAddress: KrampShippingAddress): void {
    this.krampShippingAddressService
      .deleteKrampShippingAddress(shippingAddress)
      .subscribe(
        (response) => {
          this.updateFeedback(
            this.ShippingAddressCRUDFeedbackIdentifier,
            FeedbackType.succes,
            true,
            `Het verzendadres van ${shippingAddress.name} is verwijderd!`,
            undefined,
            true
          );
          this.removeFromShippingAddresses(shippingAddress);
        },
        (error) => {
          console.error(error);
          this.updateFeedback(
            this.ShippingAddressCRUDFeedbackIdentifier,
            FeedbackType.error,
            true,
            'Er is *iets* fout gegaan bij het verwijderen van het verzendadres',
            undefined,
            true
          );
        }
      );

    this.deleteCandidateShippingAddress = undefined;
  }

  cancelEdit() {
    this.table.resetSelectedIndex();
  }

  setDeleteCandidate(shippingAddress: KrampShippingAddress): void {
    this.deleteCandidateShippingAddress = shippingAddress;
    this.feedbackSettings.show = false;
  }

  clearDeleteCandidate(): void {
    this.deleteCandidateShippingAddress = undefined;
  }

  addToShippingAddresses(shippingAddress: KrampShippingAddress): void {
    this.shippingAddresses.push(shippingAddress);
    this.table.sortByField('lastModified', false);
  }

  removeFromShippingAddresses(shippingAddress: KrampShippingAddress): void {
    this.shippingAddresses.forEach((shippingElement, index) => {
      if (shippingElement.id === shippingAddress.id) {
        this.shippingAddresses.splice(index, 1);
      }
    });
  }

  updateFeedback(
    identifier: string,
    type: FeedbackType,
    show: boolean,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ): void {
    this.feedbackSettings.identifier = identifier;
    this.feedbackSettings.type = type;
    this.feedbackSettings.show = show;
    this.feedbackSettings.message = message;
    this.feedbackSettings.buttonText = buttonText;
    this.feedbackSettings.closeButton = closeButton;
  }

  handleFeedbackOutput(identifier: string): void {
    if (identifier == this.generalFeedbackIdentifier) {
      this.router.navigate(['home']);
    } else {
      this.feedbackSettings.show = false;
    }
  }
}
