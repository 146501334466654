import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SUPPLIERS_SERVICE_URL } from 'src/app/injection-tokens';
import { Supplier } from './supplier';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(
    @Inject(SUPPLIERS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  createSupplier(supplier: Supplier): Observable<Supplier> {
    return this.http.post<Supplier>(this.serviceURL, supplier);
  }
  getSupplier(id: number): Observable<Supplier> {
    return this.http.get<Supplier>(`${this.serviceURL}/${id}`);
  }
  getSuppliers(): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(this.serviceURL);
  }

  updateSupplier(supplier: Supplier): Observable<Supplier> {
    return this.http.put<Supplier>(
      `${this.serviceURL}/${supplier.id}`,
      supplier
    );
  }

  deleteSupplier(supplier: Supplier): Observable<HttpResponse<any>> {
    return this.http.delete(
      `${this.serviceURL}/${supplier.id}`, //
      { observe: 'response' } //
    );
  }
}
