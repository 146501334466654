import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ScsnCustomersService } from '../scsn-customers.service';
import { NgModel } from '@angular/forms';
import { ExtendedSCSNCustomer } from '../extended-scsn-customer.model';
import { EmptySCSNCustomer } from '../scsn-customer.model';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { BASE_ICM_URL } from 'src/app/injection-tokens';

@Component({
  selector: 'app-scsn-customers-form',
  templateUrl: './scsn-customers-form.component.html',
  styleUrls: ['./scsn-customers-form.component.scss'],
})
export class ScsnCustomersFormComponent {
  @Input('customers') extSCSNCustomers!: ExtendedSCSNCustomer[];
  @Output('sendCreate')
  sendCreate: EventEmitter<ExtendedSCSNCustomer> = new EventEmitter();
  @Output('sendUpdate')
  sendUpdate: EventEmitter<ExtendedSCSNCustomer> = new EventEmitter();

  @ViewChild(CustomerInformationComponent)
  customerInformation!: CustomerInformationComponent;
  @ViewChild('indiCustomerNumber') indiCustomerNumber!: NgModel;
  @ViewChild('gln') gln!: NgModel;
  @ViewChild('enabled') enabled!: NgModel;
  @ViewChild('ublViaSCSN') ublViaSCSN!: NgModel;

  extSCSNCustomer: ExtendedSCSNCustomer = { scsnCustomer: EmptySCSNCustomer() };
  companyName: string = '';
  showError: boolean = false;
  showCustomerInfo: boolean = false;
  showGlnUrl: boolean = false;
  createMode: boolean = true;

  constructor(
    private scsnCustomerService: ScsnCustomersService,
    @Inject(BASE_ICM_URL) readonly baseICMUrl: string
  ) {}

  getCustomerInfo(): void {
    if (this.indiCustomerNumber.valid) {
      this.showCustomerInfo = true;
      this.customerInformation.setIsLoading(true);

      setTimeout(() => {
        this.extSCSNCustomer.scsnCustomer.indiCustomerNumber =
          this.extSCSNCustomer.scsnCustomer.indiCustomerNumber.trim();

        this.scsnCustomerService
          .getCustomerInfo(this.extSCSNCustomer.scsnCustomer.indiCustomerNumber)
          .subscribe(
            (customerInfo) => {
              if (!customerInfo) {
              } else {
                this.extSCSNCustomer!.customerInfo = customerInfo;
                this.showError = false;
              }
            },
            (error) => {
              this.showError = true;
            }
          );
        this.customerInformation.setIsLoading(false);
      }, 3000); // 3000 milliseconds = 3 seconds break so people can see my awesome loading icon
    }
  }

  hideCustomerInfo(): void {
    this.showCustomerInfo = false;
  }

  updateGLNUrlOnBlur(): void {
    this.extSCSNCustomer.scsnCustomer.gln =
      this.extSCSNCustomer.scsnCustomer.gln.trim();
    if (this.gln.valid) {
      this.showGlnUrl = true;
    }
  }

  setEditSCSNCustomer(extSCSNCustomer: ExtendedSCSNCustomer): void {
    this.extSCSNCustomer = JSON.parse(JSON.stringify(extSCSNCustomer)); // Making a copy so the table doesn't get a fake update
    this.companyName = extSCSNCustomer.customerInfo
      ? extSCSNCustomer.customerInfo.companyName
      : '';
    this.showCustomerInfo = true;
    this.createMode = false;
  }

  sendCreateOrUpdate(extSCSNCustomer: ExtendedSCSNCustomer): void {
    extSCSNCustomer.scsnCustomer.indiCustomerNumber =
      extSCSNCustomer.scsnCustomer.indiCustomerNumber.replace(/\s/g, '');

    if (this.createMode) {
      this.sendCreate.emit(extSCSNCustomer);
    } else {
      this.sendUpdate.emit(extSCSNCustomer);
    }
    this.reset();
  }

  reset(): void {
    this.extSCSNCustomer = { scsnCustomer: EmptySCSNCustomer() };
    this.indiCustomerNumber.reset();
    this.gln.reset();
    this.createMode = true;
    this.showCustomerInfo = false;
    this.showGlnUrl = false;
  }

  isFormValid(): boolean {
    if (!this.indiCustomerNumber || !this.gln) {
      return false;
    } else {
      return this.isIndiCustomerNumberValid() && this.isGLNValid();
    }
  }

  isIndiCustomerNumberValid(): boolean {
    if (
      this.indiCustomerNumber == undefined ||
      this.indiCustomerNumber.valid == null
    ) {
      return false;
    }

    return this.indiCustomerNumber.valid && this.isCustomerNumberUnique();
  }

  isGLNValid(): boolean {
    if (this.gln == undefined || this.gln.valid == null) {
      return false;
    }

    return this.gln.valid && this.isGLNUnique();
  }

  isCustomerNumberUnique(): boolean {
    let foundSCSNCustomer: ExtendedSCSNCustomer | undefined =
      this.extSCSNCustomers.find((customer) => {
        if (this.createMode) {
          return (
            customer.scsnCustomer.indiCustomerNumber! ===
            this.extSCSNCustomer.scsnCustomer.indiCustomerNumber!
          );
        } else {
          return (
            customer.scsnCustomer.indiCustomerNumber! ===
              this.extSCSNCustomer.scsnCustomer.indiCustomerNumber! &&
            customer.scsnCustomer.id != this.extSCSNCustomer.scsnCustomer.id
          );
        }
      });
    return foundSCSNCustomer == undefined;
  }

  isGLNUnique(): boolean {
    let foundSCSNCustomer: ExtendedSCSNCustomer | undefined =
      this.extSCSNCustomers.find((customer) => {
        if (this.createMode) {
          return (
            customer.scsnCustomer.gln! ===
            this.extSCSNCustomer.scsnCustomer.gln!
          );
        } else {
          return (
            customer.scsnCustomer.gln! ===
              this.extSCSNCustomer.scsnCustomer.gln! &&
            customer.scsnCustomer.id != this.extSCSNCustomer.scsnCustomer.id
          );
        }
      });
    return foundSCSNCustomer == undefined;
  }
}
