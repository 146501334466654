import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { INVOICE_SEARCH_SERVICE_URL } from 'src/app/injection-tokens';
import { SearchResult } from './search-result';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSearchService {
  constructor(
    @Inject(INVOICE_SEARCH_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getInvoiceSearchResults(
    number: string,
    getInvoices: boolean
  ): Observable<SearchResult[]> {
    let url = getInvoices
      ? `${this.serviceURL}?orderNumber=`
      : `${this.serviceURL}?invoiceNumber=`;
    return this.http.get<SearchResult[]>(url + number);
  }
}
