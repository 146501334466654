import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { ExtendedSCSNCustomer } from '../extended-scsn-customer.model';

@Component({
  selector: 'app-scsn-customers-table',
  templateUrl: './scsn-customers-table.component.html',
  styleUrls: ['./scsn-customers-table.component.scss'],
})
export class ScsnCustomersTableComponent {
  @ViewChildren('sortIcons') sortIcons!: QueryList<ElementRef>;
  @Input('extSCSNCustomers') extSCSNCustomers: ExtendedSCSNCustomer[] = [];
  @Output('sendEditSCSNCustomer')
  sendEditSCSNCustomer: EventEmitter<ExtendedSCSNCustomer> = new EventEmitter();
  @Output('sendEnableOrDisable')
  sendEnableOrDisable: EventEmitter<ExtendedSCSNCustomer> = new EventEmitter();

  constructor(private renderer: Renderer2) {}

  searchText: string = '';
  page: number = 1;
  selectedIndex: number = -1;

  sortByCustomerNumberAsc: boolean = false;
  sortByglnAsc: boolean = false;
  sortByCompanyNameAsc: boolean = false;
  sortByActiveAsc: boolean = false;
  sortByEnabledAsc: boolean = false;
  sortByUblViaInvoiceAsc: boolean = false;
  sortByCreatedAtAsc: boolean = false;
  sortByCreatedByAsc: boolean = false;
  sortByUpdatedAtAsc: boolean = false;
  sortByUpdatedByAsc: boolean = false;

  editSCSNCustomer(extSCSNCustomer: ExtendedSCSNCustomer): void {
    this.sendEditSCSNCustomer.emit(extSCSNCustomer);
  }

  sendDisable(extSCSNCustomer: ExtendedSCSNCustomer): void {
    extSCSNCustomer.scsnCustomer.enabled = false;
    this.sendEnableOrDisable.emit(extSCSNCustomer);
  }

  sendEnable(extSCSNCustomer: ExtendedSCSNCustomer): void {
    extSCSNCustomer.scsnCustomer.enabled = true;
    this.sendEnableOrDisable.emit(extSCSNCustomer);
  }
  sortByField(field: string, ascendingOrder: boolean) {
    switch (field) {
      case 'gln': {
        this.sortByglnAsc = ascendingOrder;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.gln <
                ExtendedSCSNCustomer2.scsnCustomer.gln
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.gln >
                ExtendedSCSNCustomer2.scsnCustomer.gln
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.gln <
                ExtendedSCSNCustomer2.scsnCustomer.gln
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.gln >
                ExtendedSCSNCustomer2.scsnCustomer.gln
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'customerNumber': {
        //TODO: this can be better but I dont care right now, sorry
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = ascendingOrder;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.indiCustomerNumber <
                ExtendedSCSNCustomer2.scsnCustomer.indiCustomerNumber
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.indiCustomerNumber >
                ExtendedSCSNCustomer2.scsnCustomer.indiCustomerNumber
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.indiCustomerNumber <
                ExtendedSCSNCustomer2.scsnCustomer.indiCustomerNumber
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.indiCustomerNumber >
                ExtendedSCSNCustomer2.scsnCustomer.indiCustomerNumber
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'companyName': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = ascendingOrder;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              const companyName1 =
                ExtendedSCSNCustomer1.customerInfo?.companyName || '';
              const companyName2 =
                ExtendedSCSNCustomer2.customerInfo?.companyName || '';

              if (companyName1 < companyName2) return -1;
              if (companyName1 > companyName2) return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              const companyName1 =
                ExtendedSCSNCustomer1.customerInfo?.companyName || '';
              const companyName2 =
                ExtendedSCSNCustomer2.customerInfo?.companyName || '';

              if (companyName1 < companyName2) return 1;
              if (companyName1 > companyName2) return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'active': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = ascendingOrder;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              const companyName1 =
                ExtendedSCSNCustomer1.customerInfo?.active || '';
              const companyName2 =
                ExtendedSCSNCustomer2.customerInfo?.active || '';

              if (companyName1 < companyName2) return -1;
              if (companyName1 > companyName2) return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              const companyName1 =
                ExtendedSCSNCustomer1.customerInfo?.active || '';
              const companyName2 =
                ExtendedSCSNCustomer2.customerInfo?.active || '';

              if (companyName1 < companyName2) return 1;
              if (companyName1 > companyName2) return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'enabled': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = ascendingOrder;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.enabled <
                ExtendedSCSNCustomer2.scsnCustomer.enabled
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.enabled >
                ExtendedSCSNCustomer2.scsnCustomer.enabled
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.enabled <
                ExtendedSCSNCustomer2.scsnCustomer.enabled
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.enabled >
                ExtendedSCSNCustomer2.scsnCustomer.enabled
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'ublViaInvoice': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = ascendingOrder;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.ublViaSCSN <
                ExtendedSCSNCustomer2.scsnCustomer.ublViaSCSN
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.ublViaSCSN >
                ExtendedSCSNCustomer2.scsnCustomer.ublViaSCSN
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.ublViaSCSN <
                ExtendedSCSNCustomer2.scsnCustomer.ublViaSCSN
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.ublViaSCSN >
                ExtendedSCSNCustomer2.scsnCustomer.ublViaSCSN
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'createdAt': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = ascendingOrder;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdAt! <
                ExtendedSCSNCustomer2.scsnCustomer.createdAt!
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdAt! >
                ExtendedSCSNCustomer2.scsnCustomer.createdAt!
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdAt! <
                ExtendedSCSNCustomer2.scsnCustomer.createdAt!
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdAt! >
                ExtendedSCSNCustomer2.scsnCustomer.createdAt!
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'createdBy': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = ascendingOrder;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdBy! <
                ExtendedSCSNCustomer2.scsnCustomer.createdBy!
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdBy! >
                ExtendedSCSNCustomer2.scsnCustomer.createdBy!
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdBy!! <
                ExtendedSCSNCustomer2.scsnCustomer.createdBy!!
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.createdBy!! >
                ExtendedSCSNCustomer2.scsnCustomer.createdBy!!
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'updatedAt': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = ascendingOrder;
        this.sortByUpdatedByAsc = false;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedAt! <
                ExtendedSCSNCustomer2.scsnCustomer.updatedAt!
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedAt! >
                ExtendedSCSNCustomer2.scsnCustomer.updatedAt!
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedAt! <
                ExtendedSCSNCustomer2.scsnCustomer.updatedAt!
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedAt! >
                ExtendedSCSNCustomer2.scsnCustomer.updatedAt!
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }
      case 'updatedBy': {
        this.sortByglnAsc = false;
        this.sortByCustomerNumberAsc = false;
        this.sortByCompanyNameAsc = false;
        this.sortByActiveAsc = false;
        this.sortByEnabledAsc = false;
        this.sortByUblViaInvoiceAsc = false;
        this.sortByCreatedAtAsc = false;
        this.sortByCreatedByAsc = false;
        this.sortByUpdatedAtAsc = false;
        this.sortByUpdatedByAsc = ascendingOrder;

        if (ascendingOrder) {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedBy! <
                ExtendedSCSNCustomer2.scsnCustomer.updatedBy!
              )
                return -1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedBy! >
                ExtendedSCSNCustomer2.scsnCustomer.updatedBy!
              )
                return 1;
              return 0;
            }
          );
        } else {
          this.extSCSNCustomers = this.extSCSNCustomers.sort(
            (ExtendedSCSNCustomer1, ExtendedSCSNCustomer2) => {
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedBy! <
                ExtendedSCSNCustomer2.scsnCustomer.updatedBy!
              )
                return 1;
              if (
                ExtendedSCSNCustomer1.scsnCustomer.updatedBy! >
                ExtendedSCSNCustomer2.scsnCustomer.updatedBy!
              )
                return -1;
              return 0;
            }
          );
        }
        break;
      }

      default: {
        break;
      }
    }
    this.updateSortingHighlight(`${field}${ascendingOrder ? 'Asc' : 'Desc'}`);
  }

  updateSortingHighlight(highlightElement: string): void {
    this.sortIcons.forEach((element: ElementRef) => {
      if (element.nativeElement.id == highlightElement) {
        this.renderer.addClass(element.nativeElement, 'highlight');
      } else {
        this.renderer.removeClass(element.nativeElement, 'highlight');
      }
    });
  }
}
