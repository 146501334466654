<h1 class="text-center border-bottom pb-3">Retouren uitval</h1>
<div class="text-center my-3">
  Wij gaan voor je kijken of er retourbevestigingen zijn met fouten (in de
  afgelopen 14 dagen).
</div>
<div class="row" [ngClass]="{ 'd-none': feedbackSettings.show || isLoading }">
  <div *ngIf="returnFailures.length > 0; else noResults">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="width-10">Id</th>
          <th scope="col" class="width-20">Datum</th>
          <th scope="col" class="width-15">Order</th>
          <th scope="col" class="ps-4">Fouten, per orderregel</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let returnFailure of returnFailures">
          <th scope="row">{{ returnFailure.orderId }}</th>
          <td>
            {{ returnFailure.creationDateTime | cleanDate : "date-time" }}
          </td>
          <td>
            <a
              href="{{ returnFailure.orderId | omsOrderLink }}"
              target="_blank"
              >{{ returnFailure.shopOrderNumber }}</a
            >
          </td>
          <td>
            <ol class="fa-ul">
              <li *ngFor="let error of returnFailure.errors">
                <span class="fa-li"
                  ><i class="fa-solid fa-triangle-exclamation"></i
                ></span>
                <span class="fw-semibold">{{ error.orderPosNo }}</span
                >: {{ error.errorText }}
              </li>
            </ol>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #noResults>
    <div class="d-flex justify-content-center mt-5">
      <div class="p-5 border">
        <img [src]="'assets/cat.png'" height="100" />
        <span class="ms-4 fs-4"> Goed bezig, de lijst is leeg! </span>
      </div>
    </div>
  </ng-template>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback
    [(settings)]="feedbackSettings"
    (update)="returnHome()"
  ></app-feedback>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
