import { Pipe, PipeTransform } from '@angular/core';
import { KrampSpecial } from './kramp-special';

@Pipe({
  name: 'searchKrampSpecials',
})
export class SearchKrampSpecialsPipe implements PipeTransform {
  transform(items: KrampSpecial[], searchText: string): KrampSpecial[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.sku.toLowerCase().includes(searchText) ||
        it.description.toLowerCase().includes(searchText) ||
        it.krampSpecialCode.toLowerCase().includes(searchText) ||
        it.krampSupplierSku.toLowerCase().includes(searchText) ||
        it.krampSupplierName?.toLowerCase().includes(searchText) ||
        it.creationBy.toLowerCase().includes(searchText)
      );
    });
  }
}
