import { Component, Input } from '@angular/core';
import { QuoteLine } from '../quote-line';

@Component({
  selector: 'app-quote-table',
  templateUrl: './quote-table.component.html',
  styleUrls: ['./quote-table.component.scss'],
})
export class QuoteTableComponent {
  @Input('quoteLines') quoteLines: QuoteLine[] = [];
  @Input('totalCostPrice') totalCostPrice: number = 0;

  totalSalesMargin: number = 0;
  autoFillDiscount: number = 0;

  totalListPrice: number = 0;
  totalMargin: number = 0;
  totalSalesPrice: number = 0;

  onQuoteLineChanged(): void {
    this.computeTotals();
  }

  computeTotals() {
    this.totalCostPrice = 0;
    this.totalListPrice = 0;
    for (const quoteLine of this.quoteLines.values()) {
      if (quoteLine.found) {
        this.totalCostPrice += quoteLine.lineCostPrice!;
        this.totalListPrice += quoteLine.lineListPrice!;
      }
    }

    if (this.totalListPrice < 0.00001) {
      this.totalMargin = 0;
    } else {
      this.totalMargin =
        (this.totalListPrice - this.totalCostPrice) /
        (this.totalListPrice / 100);
    }
    this.totalSalesPrice =
      this.totalCostPrice / (1 - this.totalSalesMargin / 100);
  }
}
