import { Component, OnInit, ViewChild } from '@angular/core';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { Return } from './return';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { ReturnsService } from './returns.service';
import { ReturnItem } from './returnItem';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';
import { Router } from '@angular/router';
import { ProcessedFile } from './processedFile';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss'],
})
export class ReturnsComponent implements OnInit {
  @ViewChild(FeedbackComponent)
  feedbackComponent!: FeedbackComponent;
  returnItems: ReturnItem[] = [];
  processedFiles: ProcessedFile[] = [];
  processedFilesLimit: number = 3;
  processedFileError: boolean = false;
  isLoading: boolean = false;
  showFeedback: boolean = false;
  page: number = 1;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(private returnsService: ReturnsService, private router: Router) {}

  ngOnInit(): void {
    this.getUnprocessedReturns();
    this.getProcessedFiles();
  }

  getProcessedFiles() {
    this.returnsService.getProcessedFiles(this.processedFilesLimit).subscribe(
      (files) => {
        this.processedFiles = files;
      },
      (error) => {
        this.processedFileError = true;
      }
    );
  }

  getUnprocessedReturns() {
    this.isLoading = true;
    this.returnsService.getUnprocessedReturns().subscribe(
      (returns) => {
        this.returnItems = this.getReturnItemList(returns);
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          FeedbackType.error,

          'Er is iets fout gegaan bij het ophalen van de retouren',
          'Terug naar hoofdmenu'
        );
        this.isLoading = false;
      }
    );
  }

  getReturnItemList(returns: Return[]): ReturnItem[] {
    const returnItems: ReturnItem[] = [];
    returns.forEach((element) => {
      element.returnItems.forEach((element) => {
        returnItems.push(element);
      });
    });
    return returnItems;
  }

  updateFeedback(type: FeedbackType, message: string, buttonText: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = false;
    this.feedbackSettings.buttonText = buttonText;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  returnHome() {
    this.router.navigate(['home']);
  }
}
