import { Component, ElementRef, ViewChild } from '@angular/core';
import { Manco } from '../manco-to-check-up-on';

@Component({
  selector: 'app-manco-dialog',
  templateUrl: './manco-dialog.component.html',
  styleUrls: ['./manco-dialog.component.scss'],
})
export class MancoDialogComponent {
  @ViewChild('appDialog')
  dialog!: ElementRef<HTMLDialogElement>;

  data?: Manco;

  async showModal(manco: Manco): Promise<Manco | undefined> {
    this.data = { ...manco }; // Create a copy of the data
    this.dialog.nativeElement.showModal();

    return new Promise((resolve) => {
      this.dialog.nativeElement.addEventListener(
        'close',
        () => {
          resolve(
            this.dialog.nativeElement.returnValue === 'ok'
              ? this.data
              : undefined
          );
        },
        { once: true }
      );
    });
  }

  closeDialog(result: 'ok' | 'cancel') {
    this.dialog.nativeElement.returnValue = result;
    this.dialog.nativeElement.close(result);
  }

  handleSubmit(updatedData: Manco) {
    this.data = updatedData;
    this.closeDialog('ok');
  }
}
