export interface SCSNCustomer {
  id: number;
  indiCustomerNumber: string;
  gln: string;
  enabled: boolean;
  ublViaSCSN: boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export const EmptySCSNCustomer = (): SCSNCustomer => ({
  id: 0,
  indiCustomerNumber: '',
  gln: '',
  enabled: true,
  ublViaSCSN: false,
});
