export interface KrampShippingAddress {
  id?: number;
  customerId: string;
  name: string;
  address: string;
  zipCode: string;
  city: string;
  shipToNo: string;
  hasNightShipping: boolean;
  active: boolean;
  inTransit?: boolean;
  modifiedBy?: string;
  lastModified?: string;
}

export const EmptyKrampShippingAddress = (): KrampShippingAddress => ({
  customerId: '',
  name: '',
  address: '',
  zipCode: '',
  city: '',
  shipToNo: '',
  hasNightShipping: false,
  active: true,
});
