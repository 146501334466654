import { AuthConfig } from 'angular-oauth2-oidc';
export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_UMkWuRgNG',
  redirectUri: 'http://localhost:4200',
  clientId: 'hopof5jeaifl0jec7btn7pnrl',
  responseType: 'code',
  strictDiscoveryDocumentValidation: false,
  scope: 'openid profile email',
  showDebugInformation: false,
  timeoutFactor: 0.01,
};
