<div class="d-flex justify-content-center p-1">
  <i *ngIf="success; else error" class="fa-solid fa-check green"></i>
  <ng-template #error>
    <div
      class="icon icon--cat"
      [tooltip]="'Er is iets mis gegaan :( probeer het later opnieuw'"
      [position]="TooltipPosition.LEFT"
    >
      <i class="fa-solid fa-triangle-exclamation red"></i>
    </div>
  </ng-template>
</div>
