import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SHIPPING_ADDRESSES_URL } from 'src/app/injection-tokens';
import { KrampShippingAddress } from './kramp-shipping-address';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KrampShippingAddressService {
  constructor(
    @Inject(SHIPPING_ADDRESSES_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  createKrampShippingAddress(
    krampSpecial: KrampShippingAddress
  ): Observable<KrampShippingAddress> {
    return this.http.post<KrampShippingAddress>(this.serviceURL, krampSpecial);
  }
  getKrampShippingAddresses(): Observable<KrampShippingAddress[]> {
    return this.http.get<KrampShippingAddress[]>(this.serviceURL);
  }

  updateKrampShippingAddress(
    shippingAddress: KrampShippingAddress
  ): Observable<KrampShippingAddress> {
    return this.http.put<KrampShippingAddress>(
      `${this.serviceURL}/${shippingAddress.id}`,
      shippingAddress
    );
  }

  deleteKrampShippingAddress(
    shippingAddress: KrampShippingAddress
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      `${this.serviceURL}/${shippingAddress.id}`, //
      { observe: 'response' } //
    );
  }
}
