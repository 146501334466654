import { Pipe, PipeTransform } from '@angular/core';
import { TUSpecial } from './tu-special';

@Pipe({
  name: 'searchTUSpecials',
})
export class SearchTUSpecialsPipe implements PipeTransform {
  transform(items: TUSpecial[], searchText: string): TUSpecial[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.sku.toLowerCase().includes(searchText) ||
        it.description.toLowerCase().includes(searchText) ||
        it.supplierSKU.toLowerCase().includes(searchText) ||
        it.class2?.toLowerCase().includes(searchText) ||
        it.creationBy.toLowerCase().includes(searchText)
      );
    });
  }
}
