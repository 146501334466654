<div>
  <div class="d-flex justify-content-end mb-3">
    <div class="searchbar">
      <input
        class="search_input"
        type="text"
        [(ngModel)]="searchText"
        placeholder="Zoeken..."
      />
      <div class="search_icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('gln', !sortByglnAsc)"
        >
          <span class="me-1">GLN</span>
          <div
            #sortIcons
            id="glnAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="glnDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('customerNumber', !sortByCustomerNumberAsc)"
        >
          <span class="d-xxl-none me-1">Klantnr</span>
          <span class="d-none d-xxl-inline me-1">Klantnummer</span>
          <div
            #sortIcons
            id="customerNumberAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="customerNumberDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-15 sort"
          (click)="sortByField('companyName', !sortByCompanyNameAsc)"
        >
          <span class="me-1">Bedrijfsnaam</span>
          <div
            #sortIcons
            id="companyNameAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="companyNameDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('active', !sortByActiveAsc)"
        >
          <span class="d-xxl-none me-1">Stop</span>
          <span class="d-none d-xxl-inline me-1">Orderstop</span>
          <div
            #sortIcons
            id="activeAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="activeDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('enabled', !sortByEnabledAsc)"
        >
          <span class="d-xxl-none me-1">SCSN</span>
          <span class="d-none d-xxl-inline me-1">Aangesloten</span>
          <div
            #sortIcons
            id="enabledAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="enabledDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('ublViaInvoice', !sortByUblViaInvoiceAsc)"
        >
          <span class="me-1">UBL</span>
          <div
            #sortIcons
            id="ublViaInvoiceAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="ublViaInvoiceDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('createdAt', !sortByCreatedAtAsc)"
        >
          <span class="d-xxl-none me-1">Gemaakt</span>
          <span class="d-none d-xxl-inline me-1">Aangemaakt op</span>
          <div
            #sortIcons
            id="createdAtAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="createdAtDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('createdBy', !sortByCreatedByAsc)"
        >
          <span class="d-xxl-none me-1">Aanmaker</span>
          <span class="d-none d-xxl-inline me-1">Aangemaakt door</span>
          <div
            #sortIcons
            id="createdByAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="createdByDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('updatedAt', !sortByUpdatedAtAsc)"
        >
          <span class="d-xxl-none me-1">Aangepast</span>
          <span class="d-none d-xxl-inline me-1">Laatst aangepast</span>
          <div
            #sortIcons
            id="updatedAtAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="updatedAtDesc"
            class="fa-solid fa-sort-down sort-icon highlight"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('updatedBy', !sortByUpdatedByAsc)"
        >
          <span class="d-xxl-none me-1">Editor</span>
          <span class="d-none d-xxl-inline me-1">Aangepast door</span>
          <div
            #sortIcons
            id="updatedByAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="updatedByDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th scope="col" class="width-10">Acties</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let extSCSNCustomer of extSCSNCustomers
            | searchExtendedSCSNCustomers : searchText
            | paginate : { itemsPerPage: 10, currentPage: page };
          index as i
        "
        [ngClass]="{ 'fw-bolder primary-color': i === selectedIndex }"
      >
        <td>{{ extSCSNCustomer.scsnCustomer.gln }}</td>
        <td>
          <div *ngIf="extSCSNCustomer.customerInfo; else noLink">
            <a
              href="{{
                extSCSNCustomer.customerInfo.icmLinkID | icmCustomerLink
              }}"
              target="_blank"
              >{{ extSCSNCustomer.scsnCustomer.indiCustomerNumber }}</a
            >
          </div>
          <ng-template #noLink>
            <div>{{ extSCSNCustomer.scsnCustomer.indiCustomerNumber }}</div>
          </ng-template>
        </td>
        <td>
          <div *ngIf="extSCSNCustomer.customerInfo; else showCustomerWarning">
            {{ extSCSNCustomer.customerInfo.companyName }}
          </div>
          <ng-template #showCustomerWarning>
            <div class="red">
              <i class="fa-solid fa-triangle-exclamation"></i> Geen info
              beschikbaar, check aub het klantennummer
            </div>
          </ng-template>
        </td>
        <td>
          <div *ngIf="extSCSNCustomer.customerInfo">
            <div>
              {{ extSCSNCustomer.customerInfo.active ? "Nee" : "" }}
              <span *ngIf="!extSCSNCustomer.customerInfo.active" class="red">
                <i class="fa-solid fa-triangle-exclamation"></i> Ja
              </span>
            </div>
          </div>
        </td>
        <td>{{ extSCSNCustomer.scsnCustomer.enabled | yesNo : "nl" }}</td>
        <td>{{ extSCSNCustomer.scsnCustomer.ublViaSCSN | yesNo : "nl" }}</td>
        <td>
          {{
            extSCSNCustomer.scsnCustomer.createdAt!
              | utcDate : "yyyy-MM-dd HH:mm:ss"
          }}
        </td>
        <td>{{ extSCSNCustomer.scsnCustomer.createdBy! }}</td>
        <td>
          {{
            extSCSNCustomer.scsnCustomer.updatedAt!
              | utcDate : "yyyy-MM-dd HH:mm:ss"
          }}
        </td>
        <td>{{ extSCSNCustomer.scsnCustomer.updatedBy! }}</td>
        <td>
          <div class="d-flex flex-row p-0 w-100">
            <div
              class="flex-fill"
              [ngClass]="extSCSNCustomer.scsnCustomer.enabled ? 'me-3' : 'me-1'"
            >
              <button
                type="button"
                class="btn btn-outline-primary btn-sm mw-75"
                (click)="editSCSNCustomer(extSCSNCustomer)"
              >
                <span class="d-xxl-none"
                  ><i class="fa-solid fa-pencil"></i
                ></span>
                <span class="d-none d-xxl-inline">Aanpassen</span>
              </button>
            </div>
            <div
              class="flex-fill"
              *ngIf="extSCSNCustomer.scsnCustomer.enabled; else activate"
            >
              <button
                class="btn btn-outline-danger btn-sm w-100"
                (click)="sendDisable(extSCSNCustomer)"
              >
                <span class="d-xxl-none"><i class="fa-solid fa-lock"></i></span>
                <span class="d-none d-xxl-inline">Afsluiten</span>
              </button>
            </div>
            <ng-template #activate>
              <div class="flex-fill">
                <button
                  class="btn btn-outline-success btn-sm w-100"
                  (click)="sendEnable(extSCSNCustomer)"
                >
                  <span class="d-xxl-none"
                    ><i class="fa-solid fa-unlock"></i
                  ></span>
                  <span class="d-none d-xxl-inline">Aansluiten</span>
                </button>
              </div>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls
    (pageChange)="page = $event"
    previousLabel="Vorige"
    nextLabel="Volgende"
    [autoHide]="true"
  ></pagination-controls>
</div>
