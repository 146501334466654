import { Component, OnInit } from '@angular/core';
import { DispatchFailure } from './dispatch-failure';
import { DispatchFailuresService } from './dispatch-failures.service';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';

@Component({
  selector: 'app-dispatch-failures',
  templateUrl: './dispatch-failures.component.html',
  styleUrls: ['./dispatch-failures.component.scss'],
})
export class DispatchFailuresComponent implements OnInit {
  dispatchFailures: DispatchFailure[] = [];

  isLoading: boolean = false;
  showFeedback: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(private dispatchFailuresService: DispatchFailuresService) {}

  updateFeedback(type: FeedbackType, message: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = true;
    this.feedbackSettings.buttonText = undefined;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  ngOnInit(): void {
    this.getDispatchFailures();
  }

  getDispatchFailures() {
    this.isLoading = true;
    this.dispatchFailuresService.getDispatchFailures().subscribe(
      (DispatchFailures) => {
        this.dispatchFailures = DispatchFailures;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          FeedbackType.error,
          'We konden de retour dispatches met fouten niet ophalen.'
        );
        this.isLoading = false;
      }
    );
  }
}
