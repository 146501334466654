import { Component, OnInit } from '@angular/core';
import { ReturnFailuresService } from './return-failures.service';
import { ReturnFailure } from './return-failure';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { Router } from '@angular/router';

@Component({
  selector: 'app-return-failures',
  templateUrl: './return-failures.component.html',
  styleUrls: ['./return-failures.component.scss'],
})
export class ReturnFailuresComponent implements OnInit {
  returnFailures: ReturnFailure[] = [];

  isLoading: boolean = false;
  showFeedback: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(
    private returnFailuresService: ReturnFailuresService,
    private router: Router
  ) {}

  updateFeedback(type: FeedbackType, message: string, buttonText: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = false;
    this.feedbackSettings.buttonText = buttonText;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  ngOnInit(): void {
    this.getReturnFailures();
  }

  getReturnFailures() {
    this.isLoading = true;
    this.returnFailuresService.getReturnFailures().subscribe(
      (returnFailures) => {
        this.returnFailures = returnFailures;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          FeedbackType.error,
          'We konden de retourbevestigingen met fouten niet ophalen.',
          'Terug naar hoofdmenu'
        );
        this.isLoading = false;
      }
    );
  }
  returnHome() {
    this.router.navigate(['home']);
  }
}
