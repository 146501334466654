<div>
  <div class="card-title">
    <h4>
      {{
        createMode
          ? "Nieuwe SCSN klant aansluiten:"
          : "SCSN klant " + companyName + " aanpassen:"
      }}
    </h4>
  </div>
  <div class="card-body">
    <div class="mb-1 row">
      <label for="description" class="col-lg-3 col-12 col-form-label"
        >INDI Klantennummer</label
      >

      <div class="col-lg-5 col-12">
        <input
          (blur)="getCustomerInfo()"
          type="text"
          class="form-control form-control-sm"
          [ngClass]="
            indiCustomerNumber.touched
              ? isIndiCustomerNumberValid()
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="indiCustomerNumber"
          placeholder=""
          #indiCustomerNumber="ngModel"
          [(ngModel)]="extSCSNCustomer.scsnCustomer.indiCustomerNumber"
          required
          pattern="^\s*\d{7,8}\s*$"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          Vul het INDI Klantennummer in (7-8 cijfers)
          <span *ngIf="!isCustomerNumberUnique()"
            >(dit nummer is al in gebruik)</span
          >
        </div>
        <div [ngClass]="showCustomerInfo ? 'container px-1 py-2' : 'd-none'">
          <app-customer-information
            [ngClass]="!showError ? '' : 'd-none'"
            [customerInfo]="extSCSNCustomer.customerInfo"
            [searchedNumber]="indiCustomerNumber.value"
          ></app-customer-information>
          <div [ngClass]="showError ? '' : 'd-none'">
            <p class="red m-0">
              Er was een probleem bij het ophalen van de klantinformatie,
            </p>
            <a href="{{ baseICMUrl }}" target="_blank">Zoek hier zelf</a>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="gln" class="col-lg-3 col-12 col-form-label">GLN </label>
      <div class="col-lg-5 col-12">
        <input
          type="text"
          class="form-control form-control-sm"
          (blur)="updateGLNUrlOnBlur()"
          [ngClass]="
            gln.touched ? (isGLNValid()! ? 'is-valid' : 'is-invalid') : ''
          "
          id="gln"
          placeholder="87193........"
          #gln="ngModel"
          [(ngModel)]="extSCSNCustomer.scsnCustomer.gln"
          required
          pattern="^\s*\d{13}\s*$"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          Vul het GLN in (13 cijfers)
          <span *ngIf="!isGLNUnique()">(dit nummer is al in gebruik)</span>
        </div>
        <div *ngIf="showGlnUrl">
          <a
            href="https://www.gs1.org/services/verified-by-gs1/results?gln={{
              extSCSNCustomer.scsnCustomer.gln
            }}"
            target="_blank"
            >GLN Valideren</a
          >
        </div>
      </div>
    </div>
    <div class="form-check mb-3">
      <input
        type="checkbox"
        class="form-check-input"
        id="ublViaInvoice"
        #ublViaInvoice="ngModel"
        [(ngModel)]="extSCSNCustomer.scsnCustomer.ublViaSCSN"
        value="extSCSNCustomer.scsnCustomer.ublViaSCSN"
      />
      <label class="form-check-label" for="shippingAddressIsActive"
        >UBL factuur via SCSN?</label
      >
    </div>
    <div class="form-check mb-3">
      <input
        type="checkbox"
        class="form-check-input"
        id="enabled"
        #enabled="ngModel"
        [(ngModel)]="extSCSNCustomer.scsnCustomer.enabled"
        value="extSCSNCustomer.scsnCustomer.enabled"
      />
      <label class="form-check-label" for="enabled">Direct aansluiten?</label>
    </div>

    <div class="d-flex flex-row mb-3">
      <div class="me-3">
        <button type="button" class="btn btn-outline-danger" (click)="reset()">
          Annuleren
        </button>
      </div>
      <div>
        <button
          type="button"
          (click)="sendCreateOrUpdate(extSCSNCustomer)"
          class="btn btn-primary"
          [disabled]="!isFormValid()"
        >
          {{ createMode ? "Toevoegen" : "Aanpassen" }}
        </button>
      </div>
    </div>
  </div>
</div>
