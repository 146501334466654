import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SPECIALS_SERVICE_URL } from 'src/app/injection-tokens';
import { KrampSpecial } from './kramp-special';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KrampSpecialsService {
  constructor(
    @Inject(SPECIALS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  createKrampSpecial(krampSpecial: KrampSpecial): Observable<KrampSpecial> {
    return this.http.post<KrampSpecial>(
      this.serviceURL + '/kramp',
      krampSpecial
    );
  }
  getKrampSpecial(id: number): Observable<KrampSpecial> {
    return this.http.get<KrampSpecial>(`${this.serviceURL}/kramp/${id}`);
  }
  getKrampSpecials(): Observable<KrampSpecial[]> {
    return this.http.get<KrampSpecial[]>(this.serviceURL + '/kramp');
  }
}
