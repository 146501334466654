export interface Supplier {
  id?: number;
  supplierId?: number;
  name: string;
  lastUserModified?: string;
  lastModified?: string;
}

export const EmptySupplier = (): Supplier => ({
  name: '',
});
