<button type="button" (click)="toggleNewSpecial()" class="btn btn-primary my-3">
  {{ showForm ? "Formulier verbergen" : "Nieuwe Kramp special toevoegen" }}
</button>
<div class="card border-0" [ngClass]="{ 'd-none': !showForm }">
  <div class="card-body">
    <div class="mb-2 row">
      <label for="sku" class="col-sm-4 col-form-label">Artikelnummer</label>
      <div class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-sm form-control-plaintext"
          id="sku"
          [(ngModel)]="reservedSku.sku"
        />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="description" class="col-sm-4 col-form-label"
        >Omschrijving</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="
            description.touched
              ? description.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="description"
          placeholder=""
          #description="ngModel"
          [(ngModel)]="newSpecial.description"
          required
          minlength="1"
          maxlength="400"
          pattern="^[^;]*$"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          Vul een omschrijving in. Puntkomma's (;) zijn niet toegestaan.
        </div>
        <div class="remark">(NB dit moet uniek zijn)</div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="specialCode" class="col-sm-4 col-form-label"
        >SpecialCode</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="
            specialCode.touched
              ? specialCode.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="specialCode"
          placeholder=""
          list="specCodes"
          #specialCode="ngModel"
          [(ngModel)]="searchTextSpecialCode"
          required
          minlength="7"
          maxlength="20"
          pattern="^[A-Z0-9]+$"
          autocomplete="off"
          (change)="updateSpecialCode(searchTextSpecialCode)"
        />
        <div class="invalid-feedback">
          <div>
            Vul de specialCode in. Gebruik hiervoor:
            <ul class="mb-0">
              <li>Minimaal 7 karakters</li>
              <li>Maximaal 20 karakters</li>
              <li>Hoofdletters (A tot Z)</li>
              <li>Cijfers (0-9)</li>
              <li>
                Of kies er een uit de lijst (klik op het veld als deze leeg is)
              </li>
            </ul>
          </div>
          <div
            *ngIf="this.specialCode.value && this.specialCode.value.length > 6"
          >
            De volgende tekens zijn niet toegestaan:
            {{ specialCode.value | removeValidCharacters : "[A-Z0-9]+" }}
          </div>
        </div>
        <datalist id="specCodes">
          <option
            *ngFor="let specialCode of specialCodes"
            [value]="specialCode.code"
          >
            {{ specialCode.code }} | {{ specialCode.name }}
          </option>
        </datalist>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="supplier" class="col-sm-4 col-form-label">Leverancier</label>
      <div class="col-sm-8">
        <select
          class="form-select form-select-sm"
          [ngClass]="
            supplier.touched
              ? supplier.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="supplier"
          #supplier="ngModel"
          [(ngModel)]="newSpecial.krampSupplierId"
          required
          autocomplete="off"
        >
          <option
            [value]="supplier.supplierId"
            *ngFor="let supplier of suppliers"
          >
            {{ supplier.name }} ({{ supplier.supplierId }})
          </option>
        </select>
        <div class="invalid-feedback">Kies een leverancier</div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="supplierSku" class="col-sm-4 col-form-label"
        >Leverancier artikelnummer</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="
            supplierSku.touched
              ? supplierSku.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="supplierSku"
          placeholder=""
          #supplierSku="ngModel"
          [(ngModel)]="newSpecial.krampSupplierSku"
          required
          pattern='^[A-Za-z0-9 \/:=+Ø"#&(),.-]+$'
          minlength="1"
          maxlength="30"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          <div>
            Vul het artikelnummer van de leverancier in. Gebruik hiervoor:
            <ul class="mb-0">
              <li>Maximaal 30 karakters</li>
              <li>Letters (a tot z en A tot Z)</li>
              <li>Cijfers (0-9)</li>
              <li>Spaties</li>
              <li>De tekens / : = + Ø " # & ( ) , . -</li>
            </ul>
          </div>
          <div
            *ngIf="this.supplierSku.value && this.supplierSku.value.length != 0"
          >
            De volgende tekens zijn niet toegestaan:
            {{
              supplierSku.value
                | removeValidCharacters : '[A-Za-z0-9 \/:=+Ø"#&(),.-]+'
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="purchasePrice" class="col-sm-4 col-form-label"
        >Inkoopprijs</label
      >
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control form-control-sm"
          [ngClass]="
            purchasePrice.touched
              ? purchasePrice.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="purchasePrice"
          placeholder="0.00"
          #purchasePrice="ngModel"
          [(ngModel)]="newSpecial.purchasePrice"
          required
          min="0.0001"
          max="4000000"
          step="0.0001"
          autocomplete="off"
          (ngModelChange)="updatePricing()"
          (change)="toFixed(newSpecial.purchasePrice!, 'purchasePrice')"
        />
        <div class="invalid-feedback">
          Vul een inkoopprijs in tussen 0.0001 en 4000000
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="supplierPrice" class="col-sm-4 col-form-label"
        >Leverancierprijs</label
      >
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control form-control-sm"
          [ngClass]="
            supplierPrice.valid! &&
            (supplierPrice.touched || purchasePrice.touched)
              ? 'is-valid'
              : (supplierPrice.touched && !supplierPrice.valid!) ||
                supplierPrice.value > 4000000
              ? 'is-invalid'
              : ''
          "
          id="supplierPrice"
          placeholder="0.00"
          #supplierPrice="ngModel"
          [(ngModel)]="newSpecial.supplierPrice"
          required
          min="0.0001"
          max="4000000"
          step="0.0001"
          autocomplete="off"
          (ngModelChange)="updateMargin()"
          (change)="toFixed(newSpecial.supplierPrice!, 'supplierPrice')"
        />
        <div class="invalid-feedback">
          Vul een leverancierprijs in tussen 0.0001 en 4000000
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="salesPrice" class="col-sm-4 col-form-label"
        >Verkoopprijs</label
      >
      <div class="col-sm-5">
        <input
          type="number"
          class="form-control form-control-sm"
          [ngClass]="
            salesPrice.valid! && (salesPrice.touched || margin)
              ? 'is-valid'
              : (salesPrice.touched && !salesPrice.valid!) ||
                salesPrice.value > 4000000
              ? 'is-invalid'
              : ''
          "
          id="salesPrice"
          placeholder="0.00"
          #salesPrice="ngModel"
          [(ngModel)]="newSpecial.salesPrice"
          required
          min="0.0001"
          max="4000000"
          step="0.0001"
          autocomplete="off"
          (ngModelChange)="updateMargin()"
          (change)="toFixed(newSpecial.salesPrice!, 'salesPrice')"
        />
        <div class="invalid-feedback">
          Vul een verkoopprijs in tussen de 0.0001 en 4000000
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group input-group-sm">
          <input
            type="number"
            class="form-control form-control-sm"
            [ngClass]="
              margin
                ? margin >= 0 && margin <= 99.99
                  ? 'is-valid'
                  : 'is-invalid'
                : ''
            "
            placeholder="Marge"
            [(ngModel)]="margin"
            aria-label="margin"
            aria-describedby="margin"
            step="0.01"
            min="0"
            max="99.99"
            (ngModelChange)="updateSalesPrice()"
          />
          <span class="input-group-text">%</span>
          <div class="invalid-feedback">
            Vul een marge in tussen de 0 en 99.99
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="orderUnit" class="col-sm-4 col-form-label"
        >Besteleenheid</label
      >
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control form-control-sm"
          [ngClass]="
            orderUnit.touched
              ? orderUnit.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="orderUnit"
          placeholder="1"
          #orderUnit="ngModel"
          [(ngModel)]="newSpecial.orderUnit"
          required
          min="1"
          max="4000000"
          step="1"
          autocomplete="off"
        />
      </div>
      <div class="invalid-feedback">Vul het besteleenheid in</div>
    </div>
    <div class="mb-2 row">
      <label for="deliveryDays" class="col-sm-4 col-form-label"
        >Over hoeveel kalenderdagen komt de special</label
      >
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control form-control-sm"
          [ngClass]="
            deliveryDays.touched
              ? deliveryDays.valid!
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="deliveryDays"
          placeholder=""
          #deliveryDays="ngModel"
          [(ngModel)]="newSpecial.deliveryDays"
          required
          min="0"
          max="4000000"
          autocomplete="off"
        />
        <div class="remark">(0 -> beïnvloed de datum niet)</div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="availability" class="col-sm-4 col-form-label"
        >Beschikbaarheid</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-sm form-control-plaintext"
          id="availability"
          value="Deze special zal 2 maanden beschikbaar zijn."
        />
      </div>
    </div>
    <div>
      <button
        type="button"
        (click)="createSpecial(newSpecial, false)"
        class="btn btn-primary"
        [disabled]="!isFormValid()"
      >
        Toevoegen
      </button>
      <button
        type="button"
        (click)="createSpecial(newSpecial, true)"
        class="btn btn-primary mx-4"
        [disabled]="!isFormValid()"
      >
        Toevoegen en behoud velden
      </button>
    </div>
  </div>
</div>
