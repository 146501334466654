<h1 class="text-center border-bottom pb-3">Factuur / order zoeken</h1>

<div
  class="row pt-3"
  [ngClass]="{ 'd-none': feedbackSettings.show || isLoading }"
>
  <div class="col-6 col-lg-3">
    <form (ngSubmit)="getSearchResults(orderNumberInput, true)">
      <div class="input-group mb-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="orderNumberInput"
            [(ngModel)]="orderNumberInput"
            name="orderNumberInput"
            placeholder="Ordernummer"
          />
          <label for="orderNumberInput">Ordernummer</label>
        </div>
        <button class="btn btn-outline-primary" type="submit">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </form>
    <form (ngSubmit)="getSearchResults(invoiceNumberInput, false)">
      <div class="input-group mb-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="invoiceNumberInput"
            id="invoiceNumberInput"
            name="invoiceNumberInput"
            placeholder="factuurnummer"
          />
          <label for="invoiceNumberInput">Factuurnummer</label>
        </div>

        <button class="btn btn-outline-primary" type="submit">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </form>
  </div>
  <div class="col" *ngIf="!isLoading">
    <div class="d-flex justify-content-center">
      <div class="p-5 border" *ngIf="hasSearched && searchResults.length == 0">
        <i
          class="fa-regular fa-magnifying-glass fa-2xl"
          style="vertical-align: -0.145rem"
        ></i>
        <span class="ms-3 fs-4">
          Geen {{ showInvoices ? "facturen" : "orders" }} gevonden!
        </span>
      </div>
    </div>
  </div>
</div>

<div
  class="row pt-3"
  [ngClass]="{ 'd-none': feedbackSettings.show || isLoading }"
>
  <div *ngIf="searchResults.length > 0">
    <div class="mb-4">
      <h6 *ngIf="showInvoices; else searchByInvoiceHeader">
        Ordernummer
        <a
          href="{{ searchResults[0].orderId | omsOrderLink }}"
          target="_blank"
          >{{ orderNumberInput.trim() }}</a
        >&nbsp;({{
          searchResults[0].orderCreationDateTime.substring(0, 10)
            | cleanDate : "date"
        }}) heeft de volgende facturen:
      </h6>
      <ng-template #searchByInvoiceHeader>
        <h6>
          Factuurnummer
          <a
            href="{{ this.searchResults[0].invoiceId | omsInvoiceLink }}"
            target="_blank"
            >{{ invoiceNumberInput.trim() }}</a
          >&nbsp;({{
            searchResults[0].invoiceCreationDateTime.substring(0, 10)
              | cleanDate : "date"
          }}) hoort bij de volgende orders:
        </h6>
      </ng-template>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="width-15">
            {{ showInvoices ? "Factuur" : "Order" }}
          </th>
          <th scope="col" class="width-20">Datum</th>
          <th scope="col">Orderbedrag (brutto / netto)</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let searchResult of searchResults">
          <td>
            <a
              href="{{
                showInvoices
                  ? (searchResult.invoiceId | omsInvoiceLink)
                  : (searchResult.orderId | omsOrderLink)
              }}"
              target="_blank"
            >
              {{
                showInvoices
                  ? searchResult.invoiceNumber
                  : searchResult.shopOrderNumber
              }}
            </a>
          </td>
          <td>
            {{
              showInvoices
                ? (searchResult.invoiceCreationDateTime
                  | cleanDate : "date-time")
                : (searchResult.orderCreationDateTime | cleanDate : "date-time")
            }}
          </td>
          <td>
            {{ searchResult.salesSumGross | currencyformatpipe }} /
            {{ searchResult.salesSumNet | currencyformatpipe }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback [(settings)]="feedbackSettings"></app-feedback>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
