<h1 class="text-center border-bottom pb-3">Leveranciers</h1>

<div [ngClass]="{ 'd-none': isLoading }">
  <div class="row" *ngIf="!feedbackSettings.show">
    <div class="col-6">
      <div class="card border-0">
        <div class="card-title">
          <h4>
            {{
              createMode
                ? "Nieuwe Leverancier toevoegen:"
                : "Leverancier aanpassen:"
            }}
          </h4>
        </div>
        <div class="card-body w-100%">
          <div class="row g-0 justify-content-start mb-3">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                [ngClass]="
                  supplierName.touched && supplierName.valid!
                    ? 'is-valid'
                    : supplierName.touched && !supplierName.valid!
                    ? 'is-invalid'
                    : ''
                "
                id="supplierName"
                placeholder="leverancier naam"
                #supplierName="ngModel"
                [(ngModel)]="selectedSupplier.name"
                required
                minlength="1"
                maxlength="40"
                autocomplete="off"
              />
              <label for="supplierName">Naam</label>
              <div class="invalid-feedback">Vul een geldige naam in</div>
            </div>
            <div class="form-floating mb-3">
              <input
                type="number"
                class="form-control"
                [ngClass]="{
                  'is-invalid': supplierId.touched && !isSupplierIdValid()
                }"
                [ngClass]="
                  supplierId.touched && isSupplierIdValid()
                    ? 'is-valid'
                    : supplierId.touched && !isSupplierIdValid()
                    ? 'is-invalid'
                    : ''
                "
                id="supplierId"
                placeholder="leverancier nummer"
                #supplierId="ngModel"
                [(ngModel)]="selectedSupplier.supplierId"
                required
                min="1000000"
                max="999999999"
              />
              <label for="supplierid">Nummer</label>
              <div class="invalid-feedback">
                Vul een geldig nummer in (uniek en minstens 1000000)
                <span *ngIf="!isSupplierIdUnique()"
                  >(dit nummer is al in gebruik)</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex flex-row mb-3">
            <div class="me-3">
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="clearSelectedSupplier()"
              >
                Annuleren
              </button>
            </div>
            <div>
              <button
                *ngIf="!createMode"
                type="button"
                (click)="updateSupplier(selectedSupplier)"
                class="btn btn-outline-primary"
                [disabled]="!isFormValid()"
              >
                Opslaan
              </button>
              <button
                *ngIf="createMode"
                type="button"
                (click)="createSupplier(selectedSupplier)"
                class="btn btn-outline-primary"
                [disabled]="!isFormValid()"
              >
                Toevoegen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 p-0">
      <div
        class="card text-center border-0"
        style="height: 200px"
        *ngIf="deleteCandidateSupplier != undefined"
      >
        <div class="card-body">
          <div class="mb-3">
            <H4 class="mb-3"
              >Weet je zeker dat je deze leverancier wilt verwijderen?</H4
            >
            <p>
              <strong
                >{{ deleteCandidateSupplier.name }} -
                {{ deleteCandidateSupplier.supplierId }}</strong
              >
            </p>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <div class="me-5">
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="clearDeleteCandidate()"
              >
                Annuleren
              </button>
            </div>
            <div>
              <button
                type="button"
                (click)="deleteSupplier(deleteCandidateSupplier)"
                class="btn btn-outline-danger"
              >
                Verwijderen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="d-flex justify-content-center my-5">
      <app-feedback
        [(settings)]="feedbackSettings"
        (update)="handleFeedbackOutput($event)"
      ></app-feedback>
    </div>
    <div *ngIf="suppliers.length > 0">
      <div class="d-flex justify-content-end mb-3">
        <div class="searchbar">
          <input
            class="search_input"
            type="text"
            [(ngModel)]="searchText"
            placeholder="Zoeken op naam of nummer..."
          />
          <div class="search_icon"><i class="fas fa-search"></i></div>
        </div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th
              scope="col"
              class="width-30 sort"
              (click)="sortByField('name', !sortByNameAsc)"
            >
              Naam

              <div
                #sortIcons
                id="nameAsc"
                class="fa-solid fa-sort-up sort-icon"
              ></div>
              <div
                #sortIcons
                id="nameDesc"
                class="fa-solid fa-sort-down sort-icon"
              ></div>
            </th>
            <th
              scope="col"
              class="width-15 sort"
              (click)="sortByField('supplierId', !sortBySupplierIdAsc)"
            >
              Nummer
              <div
                #sortIcons
                id="supplierIdAsc"
                class="fa-solid fa-sort-up sort-icon"
              ></div>
              <div
                #sortIcons
                id="supplierIdDesc"
                class="fa-solid fa-sort-down sort-icon"
              ></div>
            </th>
            <th
              scope="col"
              class="width-20 sort"
              (click)="sortByField('lastModified', !sortByLastModifiedAsc)"
            >
              Laatst aangepast
              <div
                #sortIcons
                id="lastModifiedAsc"
                class="fa-solid fa-sort-up sort-icon"
              ></div>
              <div
                #sortIcons
                id="lastModifiedDesc"
                class="fa-solid fa-sort-down sort-icon highlight"
              ></div>
            </th>
            <th
              scope="col"
              class="width-20 sort"
              (click)="
                sortByField('lastUserModified', !sortByLastUserModifiedAsc)
              "
            >
              Aangepast door
              <div
                #sortIcons
                id="lastUserModifiedAsc"
                class="fa-solid fa-sort-up sort-icon"
              ></div>
              <div
                #sortIcons
                id="lastUserModifiedDesc"
                class="fa-solid fa-sort-down sort-icon"
              ></div>
            </th>
            <th scope="col" class="width-20">acties</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let supplier of suppliers
                | searchSuppliers : searchText
                | paginate : { itemsPerPage: 10, currentPage: page }
            "
          >
            <th scope="row">{{ supplier.name }}</th>
            <td>{{ supplier.supplierId }}</td>
            <td>{{ supplier.lastModified! | cleanDate : "date-time" }}</td>
            <td>{{ supplier.lastUserModified }}</td>
            <td>
              <div class="d-flex flex-row p-0">
                <div>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm me-3"
                    (click)="editSupplier(supplier)"
                  >
                    Aanpassen
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-outline-danger btn-sm"
                    (click)="setDeleteCandidate(supplier)"
                  >
                    Verwijderen
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="page = $event"
        previousLabel="Vorige"
        nextLabel="Volgende"
        [autoHide]="true"
      ></pagination-controls>
    </div>
  </div>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
