import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedSCSNCustomer } from './extended-scsn-customer.model';

@Pipe({
  name: 'searchExtendedSCSNCustomers',
})
export class SearchExtendedSCSNCustomers implements PipeTransform {
  transform(
    items: ExtendedSCSNCustomer[],
    searchText: string
  ): ExtendedSCSNCustomer[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.scsnCustomer.id!.toString().toLowerCase().includes(searchText) ||
        it.scsnCustomer.gln.toLowerCase().includes(searchText) ||
        it.scsnCustomer.indiCustomerNumber.toLowerCase().includes(searchText) ||
        it.customerInfo?.companyName.toLowerCase().includes(searchText) ||
        it.scsnCustomer.createdAt?.toLowerCase().includes(searchText) ||
        it.scsnCustomer.createdBy?.toLowerCase().includes(searchText) ||
        it.scsnCustomer.updatedAt?.toLowerCase().includes(searchText) ||
        it.scsnCustomer.updatedBy?.toLowerCase().includes(searchText)
      );
    });
  }
}
