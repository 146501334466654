import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SPECIALS_SERVICE_URL } from 'src/app/injection-tokens';
import { ReservedSpecialSku } from './reserved-special-sku';

@Injectable({
  providedIn: 'root',
})
export class SpecialsService {
  constructor(
    @Inject(SPECIALS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getReservedSpecialSku(): Observable<ReservedSpecialSku> {
    return this.http.post<ReservedSpecialSku>(this.serviceURL, null);
  }
}
