import { Inject, Injectable } from '@angular/core';
import { TUSpecial } from './tu-special';
import { SPECIALS_SERVICE_URL } from 'src/app/injection-tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TUSpecialsService {
  constructor(
    @Inject(SPECIALS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  createTUSpecial(TUSpecial: TUSpecial): Observable<TUSpecial> {
    return this.http.post<TUSpecial>(this.serviceURL + '/tu', TUSpecial);
  }
  getTUSpecial(id: number): Observable<TUSpecial> {
    return this.http.get<TUSpecial>(`${this.serviceURL}/tu/${id}`);
  }
  getTUSpecials(): Observable<TUSpecial[]> {
    return this.http.get<TUSpecial[]>(this.serviceURL + '/tu');
  }
}
