import { KrampSpecialCode } from './kramp-special-code';

export const KRAMP_SPECIAL_CODES: KrampSpecialCode[] = [
  { code: 'SPEC002', name: 'Machinery OE' },
  { code: 'SPEC006', name: 'Landscape Maintenance Non OE' },
  { code: 'SPEC008', name: 'Harvesters Non OE' },
  { code: 'SPEC010', name: 'Slurry, Muck Spreaders, Biogas' },
  { code: 'SPEC012', name: 'Hoses, Pipes, Pumps & Fittings' },
  { code: 'SPEC014', name: 'Tillage Non OE' },
  { code: 'SPEC016', name: 'Universal Sprayer & GPS' },
  { code: 'SPEC018', name: 'Tractor OE' },
  { code: 'SPEC020', name: 'Tractor  Non OE' },
  { code: 'SPEC022', name: 'Vehicle Electrics & Lighting' },
  { code: 'SPEC024', name: 'Universal Vehicle' },
  { code: 'SPEC026', name: 'Trailer' },
  { code: 'SPEC028', name: 'Construction OE' },
  { code: 'SPEC030', name: 'Construction Non OE' },
  { code: 'SPEC032', name: 'Tools' },
  { code: 'SPEC034', name: 'Workshop Equipment' },
  { code: 'SPEC036', name: 'Farming' },
  { code: 'SPEC038', name: 'Toys' },
  { code: 'SPEC040', name: 'Shop' },
  { code: 'SPEC042', name: 'Lubrication, Chemicals & Paint' },
  { code: 'SPEC044', name: 'Clothing & PPE' },
  { code: 'SPEC046', name: 'F&G Machinery OE' },
  { code: 'SPEC048', name: 'F&G Machinery Non OE' },
  { code: 'SPEC050', name: 'Engine & Parts OE' },
  { code: 'SPEC052', name: 'Engine Parts Non OE' },
  { code: 'SPEC054', name: 'Fasteners' },
  { code: 'SPEC056', name: 'Mechanical transmission' },
  { code: 'SPEC058', name: 'Bearings & Electric Motors' },
  { code: 'SPEC060', name: 'Pneumatics' },
  { code: 'SPEC062', name: 'Electrics Stationary' },
  { code: 'SPEC064', name: 'Hydraulic Hoses & Connectors' },
  { code: 'SPEC066', name: 'Hydraulic Cylinders & Parts' },
  { code: 'SPEC068', name: 'Hydraulic Pumps & Motors' },
  { code: 'SPEC070', name: 'Hydraulic Valves & Accessories' },
];
