import { Component } from '@angular/core';
import { InvoiceSearchService } from './invoice-search.service';
import { SearchResult } from './search-result';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';

@Component({
  selector: 'app-invoice-search',
  templateUrl: './invoice-search.component.html',
  styleUrls: ['./invoice-search.component.scss'],
})
export class InvoiceSearchComponent {
  searchResults: SearchResult[] = [];
  orderNumberInput: string = '';
  invoiceNumberInput: string = '';
  showInvoices: boolean = false;
  isLoading: boolean = false;
  showFeedback: boolean = false;
  hasSearched: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(private invoiceSearchService: InvoiceSearchService) {}

  updateFeedback(type: FeedbackType, message: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = true;
    this.feedbackSettings.buttonText = undefined;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  getSearchResults(number: string, invoices: boolean) {
    this.isLoading = true;
    this.showInvoices = invoices;
    this.showFeedback = false;
    if (number == '') {
      this.isLoading = false;
      this.hasSearched = false;
      this.updateFeedback(FeedbackType.error, 'vul aub een nummer in.');
      return;
    }
    this.invoiceSearchService
      .getInvoiceSearchResults(number.trim(), invoices)
      .subscribe(
        (searchResults) => {
          this.searchResults = searchResults;
          this.isLoading = false;
          this.hasSearched = true;
        },
        (error) => {
          this.updateFeedback(
            FeedbackType.error,
            invoices //
              ? 'We konden je facturen niet ophalen.'
              : 'We konden je orders niet ophalen.'
          );
          this.searchResults = [];
          this.hasSearched = false;
          this.isLoading = false;
        }
      );
  }
}
