import { Component, ViewChild } from '@angular/core';
import { SCSNCustomer } from './scsn-customer.model';
import { Profile } from 'src/app/general-components/profile/profile';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { ScsnCustomersService } from './scsn-customers.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { NewSCSNCustomer } from './new-scsn-customer.model';
import { CustomerInfo } from './customer-info.model';
import { ExtendedSCSNCustomer } from './extended-scsn-customer.model';
import { map, switchMap } from 'rxjs/operators';
import { ScsnCustomersTableComponent } from './scsn-customers-table/scsn-customers-table.component';
import { ScsnCustomersFormComponent } from './scsn-customers-form/scsn-customers-form.component';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';

@Component({
  selector: 'app-scsn-customers',
  templateUrl: './scsn-customers.component.html',
  styleUrls: ['./scsn-customers.component.scss'],
})
export class ScsnCustomersComponent {
  @ViewChild(ScsnCustomersTableComponent)
  scsnCustomerTable!: ScsnCustomersTableComponent;
  @ViewChild(ScsnCustomersFormComponent)
  scsnCustomerForm!: ScsnCustomersFormComponent;
  @ViewChild(FeedbackComponent)
  feedbackComponent!: FeedbackComponent;

  extSCSNCustomers: ExtendedSCSNCustomer[] = [];

  profile: Profile | undefined;

  deleteCandidateSCSNCustomer?: SCSNCustomer;
  isLoading: boolean = false;
  failedToLoad: boolean = false;

  page: number = 1;
  constructor(
    private scsnCustomerService: ScsnCustomersService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchAndCombineSCSNCustomerData();
    this.profile = this.authService.getUserInfo();
  }

  fetchAndCombineSCSNCustomerData(): void {
    this.isLoading = true;
    this.scsnCustomerService
      .getSCSNCustomers()
      .pipe(
        map((customers) => {
          const customerNumbers = customers.map(
            (customer) => customer.indiCustomerNumber
          );
          return { customers, customerNumbers };
        }),
        switchMap(({ customers, customerNumbers }) =>
          this.scsnCustomerService
            .getBulkCustomerInfo(customerNumbers)
            .pipe(map((customerInfos) => ({ customers, customerInfos })))
        ),
        map(({ customers, customerInfos }) =>
          this.combineCustomerInfo(customers, customerInfos)
        )
      )
      .subscribe({
        next: (combinedData) => {
          this.extSCSNCustomers = combinedData;

          this.extSCSNCustomers.sort((a, b) =>
            a.scsnCustomer.updatedAt! < b.scsnCustomer.updatedAt! ? 1 : -1
          );
          this.isLoading = false;
        },
        error: (error) => {
          this.feedbackComponent.showFeedback(
            '',
            FeedbackType.error,
            'Er is iets fout gegaan bij het ophalen van de SCSN klanten',
            'Terug naar hoofdmenu',
            false
          );
          this.isLoading = false;
          this.failedToLoad = true;
        },
      });
  }

  combineCustomerInfo(
    customers: SCSNCustomer[],
    customerInfos: CustomerInfo[]
  ): ExtendedSCSNCustomer[] {
    const customerInfoMap = new Map<string, CustomerInfo>();

    customerInfos.forEach((info) => {
      customerInfoMap.set(info.customerNumber, info);
    });
    return customers.map((customer) => {
      const customerInfo = customerInfoMap.get(customer.indiCustomerNumber);
      return { scsnCustomer: customer, customerInfo }; //this will return an ExtendedSCSNCustomer with undefined CustomerInfo, we don't really expect it to happen but it's possble
    });
  }

  createSCSNCustomer(extSCSNCustomer: ExtendedSCSNCustomer): void {
    const newSCSNCustomer = NewSCSNCustomer.fromSCSNCustomer(
      extSCSNCustomer.scsnCustomer, //
      `${this.profile?.firstName} ${this.profile?.lastName}`, //
      ''
    );

    this.scsnCustomerService.createSCSNCustomer(newSCSNCustomer).subscribe({
      next: (scsnCustomer) => {
        const newExtSCSNCustomer: ExtendedSCSNCustomer = {
          scsnCustomer: scsnCustomer,
          customerInfo: extSCSNCustomer.customerInfo,
        };
        this.addToSCSNCustomers(newExtSCSNCustomer);
        this.feedbackComponent.showFeedback(
          '',
          FeedbackType.succes,
          `${extSCSNCustomer.customerInfo?.companyName} is aangesloten!`,
          undefined,
          false
        );
        this.fadeOutFeedback();
      },
      error: (error) => {
        this.feedbackComponent.showFeedback(
          '',
          FeedbackType.error,
          'Er is iets misgegaan bij het aansluiten van de SCSN Klant',
          undefined,
          false
        );
        this.fadeOutFeedback();
      },
    });
  }

  editSCSNCustomer(extSCSNCustomer: ExtendedSCSNCustomer): void {
    this.scsnCustomerForm.setEditSCSNCustomer(extSCSNCustomer);
  }

  updateSCSNCustomer(extSCSNCustomer: ExtendedSCSNCustomer): void {
    extSCSNCustomer.scsnCustomer.updatedBy = `${this.profile?.firstName} ${this.profile?.lastName}`;
    const newSCSNCustomer = NewSCSNCustomer.fromSCSNCustomer(
      extSCSNCustomer.scsnCustomer, //
      '', //
      `${this.profile?.firstName} ${this.profile?.lastName}` //
    );

    this.scsnCustomerService
      .updateSCSNCustomer(newSCSNCustomer, extSCSNCustomer.scsnCustomer.id)
      .subscribe({
        next: () => {
          this.scsnCustomerService
            .getSCSNCustomer(extSCSNCustomer.scsnCustomer.id)
            .subscribe((scsnCustomer) => {
              const updatedExtSCSNCustomer: ExtendedSCSNCustomer = {
                scsnCustomer: scsnCustomer,
                customerInfo: extSCSNCustomer.customerInfo,
              };
              this.updateSCSNCustomerInList(updatedExtSCSNCustomer);
              this.feedbackComponent.showFeedback(
                '',
                FeedbackType.succes,
                `${extSCSNCustomer.customerInfo?.companyName} is aangepast!`,
                undefined,
                false
              );
              this.fadeOutFeedback();
            });
        },
        error: (error) => {
          this.feedbackComponent.showFeedback(
            '',
            FeedbackType.error,
            'Er is iets misgegaan bij het aanpassen van de SCSN Klant.',
            undefined,
            false
          );
          this.fadeOutFeedback();
        },
      });
  }

  updateSCSNCustomerInList(extSCSNCustomer: ExtendedSCSNCustomer): void {
    let foundExtSCSNCustomer: ExtendedSCSNCustomer | undefined =
      this.extSCSNCustomers.find((s) => {
        return s.scsnCustomer.id == extSCSNCustomer.scsnCustomer.id;
      });
    if (foundExtSCSNCustomer) {
      foundExtSCSNCustomer.scsnCustomer = extSCSNCustomer.scsnCustomer;
      foundExtSCSNCustomer.customerInfo = extSCSNCustomer.customerInfo;
    }
  }

  addToSCSNCustomers(extSCSNCustomer: ExtendedSCSNCustomer): void {
    this.extSCSNCustomers.push(extSCSNCustomer);
    this.scsnCustomerTable.sortByField('updatedAt', false);
  }

  returnHome(): void {
    this.router.navigate(['home']);
  }

  fadeOutFeedback(): void {
    setTimeout(() => {
      this.feedbackComponent.fadeOut();
    }, 3000);
  }
}
