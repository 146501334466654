import { Component, Input } from '@angular/core';
import { SOSCustomer } from '../sos-customer';

@Component({
  selector: 'app-sos-customer-list',
  templateUrl: './sos-customer-list.component.html',
  styleUrls: ['./sos-customer-list.component.scss'],
})
export class SosCustomerListComponent {
  @Input('didSearch') didSearch: boolean = false;
  @Input('sosCustomers') sosCustomers: SOSCustomer[] | undefined;

  getMailToLink() {
    return (
      'mailto:?bcc=' +
      this.sosCustomers! //
        .map((sosCustomer) => sosCustomer.emailAddress) //
        .join(';')
    );
  }
}
