<h1 class="text-center border-bottom pb-3">Alle klanten met betaaltermijn</h1>
<div [ngClass]="{ 'd-none': feedbackSettings.show || isLoading }">
  <div class="text-center my-3">
    <h2>{{ paymentTerm }}</h2>
  </div>
  <div class="text-center my-3">
    De links onder het klantnummer zijn naar IOM zodat je de waarden snel kunt
    corrigeren.
  </div>
</div>
<div [ngClass]="{ 'd-none': isLoading }">
  <div class="d-flex flex-column">
    <div class="form-group align-self-end pb-2">
      <button class="btn btn-primary" routerLink="/betaaltermijnen-checken">
        Terug naar de lijst
      </button>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="width-10">Klant nummer</th>
          <th scope="col" class="width-20">Naam</th>
          <th scope="col" class="width-25">Bedrijfsnaam</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let customerReportLine of customerReportLines">
          <th scope="row">
            <a
              href="{{ customerReportLine.customerId | omsCustomerLink }}"
              target="_blank"
              >{{ customerReportLine.shopCustomerNumber }}</a
            >
          </th>
          <td>
            {{ customerReportLine.name }}
          </td>
          <td>
            {{ customerReportLine.companyName }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback [(settings)]="feedbackSettings"></app-feedback>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
