<div class="card border-0" *ngIf="workingShippingAddress">
  <div class="card-title">
    <h4>
      {{
        createMode
          ? "Nieuw verzendadres toevoegen:"
          : "Verzendadres aanpassen aanpassen:"
      }}
    </h4>
  </div>
  <div class="card-body w-100%">
    <div class="row g-0 justify-content-start mb-3">
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          [ngClass]="
            shippingAddressCustomerId.touched && isCustomerIdValid()
              ? 'is-valid'
              : shippingAddressCustomerId.touched && !isCustomerIdValid()
              ? 'is-invalid'
              : ''
          "
          id="shippingAddressCustomerId"
          placeholder="Klantnummer"
          #shippingAddressCustomerId="ngModel"
          [(ngModel)]="workingShippingAddress.customerId"
          required
          minlength="1"
          maxlength="100"
          pattern="[0-9]+"
          autocomplete="off"
        />
        <label for="shippingAddressCustomerId">Klantnummer</label>
        <div class="invalid-feedback">
          Vul een geldig klantnummer in
          <span *ngIf="!isCustomeridUnique()"
            >(dit nummer is al in gebruik)</span
          >
        </div>
      </div>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          [ngClass]="
            shippingAddressName.touched && shippingAddressName.valid!
              ? 'is-valid'
              : shippingAddressName.touched && !shippingAddressName.valid!
              ? 'is-invalid'
              : ''
          "
          id="shippingAddressName"
          placeholder="Naam"
          #shippingAddressName="ngModel"
          [(ngModel)]="workingShippingAddress.name"
          required
          minlength="1"
          maxlength="100"
          autocomplete="off"
        />
        <label for="shippingAddressName">Naam</label>
        <div class="invalid-feedback">Vul een geldige naam in</div>
      </div>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': shippingAddressAddress.touched
          }"
          [ngClass]="
            shippingAddressAddress.touched && shippingAddressAddress.valid!
              ? 'is-valid'
              : shippingAddressAddress.touched && !shippingAddressAddress.valid!
              ? 'is-invalid'
              : ''
          "
          id="shippingAddressAddress"
          placeholder="Adres"
          #shippingAddressAddress="ngModel"
          [(ngModel)]="workingShippingAddress.address"
          required
          minlength="1"
          maxlength="100"
          autocomplete="off"
        />
        <label for="shippingAddressAddress">Adres</label>
        <div class="invalid-feedback">Vul een geldig adres in</div>
      </div>
      <div class="row g-0">
        <div class="col me-2">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': shippingAddressZipCode.touched
              }"
              [ngClass]="
                shippingAddressZipCode.touched && shippingAddressZipCode.valid!
                  ? 'is-valid'
                  : shippingAddressZipCode.touched &&
                    !shippingAddressZipCode.valid!
                  ? 'is-invalid'
                  : ''
              "
              id="shippingAddressZipCode"
              placeholder="Postcode"
              #shippingAddressZipCode="ngModel"
              [(ngModel)]="workingShippingAddress.zipCode"
              required
              minlength="1"
              pattern="^[1-9][0-9]{3}\s(?!sa|sd|ss)[a-zA-Z]{2}$"
              maxlength="9"
              autocomplete="off"
            />
            <label for="shippingAddressZipCode">Postcode</label>
            <div class="invalid-feedback">
              Vul een geldig postcode in (1111 AA)
            </div>
          </div>
        </div>
        <div class="col ms-2">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': shippingAddressCity.touched
              }"
              [ngClass]="
                shippingAddressCity.touched && shippingAddressCity.valid!
                  ? 'is-valid'
                  : shippingAddressCity.touched && !shippingAddressCity.valid!
                  ? 'is-invalid'
                  : ''
              "
              id="shippingAddressCity"
              placeholder="Stad"
              #shippingAddressCity="ngModel"
              [(ngModel)]="workingShippingAddress.city"
              required
              minlength="1"
              maxlength="100"
              autocomplete="off"
            />
            <label for="shippingAddressCity">Stad</label>
            <div class="invalid-feedback">Vul een geldige stadsnaam in</div>
          </div>
        </div>
      </div>

      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': shippingAddresShipToNo.touched && !isShipToNoValid()
          }"
          [ngClass]="
            shippingAddresShipToNo.touched && isShipToNoValid()
              ? 'is-valid'
              : shippingAddresShipToNo.touched && !isShipToNoValid()
              ? 'is-invalid'
              : ''
          "
          id="shippingAddresShipToNo"
          placeholder="ShipToNo"
          #shippingAddresShipToNo="ngModel"
          [(ngModel)]="workingShippingAddress.shipToNo"
          required
          pattern="[0-9]*"
          minlength="1"
          maxlength="100"
        />
        <label for="shippingAddresShipToNo">Kramp Ship To No</label>
        <div class="invalid-feedback">
          Vul een geldig shiptoNo nummer in (uniek en minstens 1000000)
          <span *ngIf="!isShipToNoUnique()">(dit nummer is al in gebruik)</span>
        </div>
      </div>
      <div class="form-check mb-3">
        <input
          type="checkbox"
          class="form-check-input"
          id="shippingAddresHasNightShipping"
          #shippingAddressHasNightShipping="ngModel"
          [(ngModel)]="workingShippingAddress.hasNightShipping"
          value="workingShippingAddress.hasNightShipping"
        />
        <label class="form-check-label" for="shippingAddressHasNightShipping"
          >NachtBezorging?</label
        >
      </div>
      <div class="form-check mb-3">
        <input
          type="checkbox"
          class="form-check-input"
          id="shippingAddresHasNightShipping"
          #shippingAddressIsActive="ngModel"
          [(ngModel)]="workingShippingAddress.active"
          value="workingShippingAddress.isActive"
        />
        <label class="form-check-label" for="shippingAddressIsActive"
          >Actief?</label
        >
      </div>
    </div>
    <div class="d-flex flex-row mb-3">
      <div class="me-3">
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="cancel(); resetInputFields()"
        >
          Annuleren
        </button>
      </div>
      <div>
        <button
          *ngIf="!createMode"
          type="button"
          (click)="sendUpdateShippingAddress(workingShippingAddress)"
          class="btn btn-outline-primary"
          [disabled]="!isFormValid()"
        >
          Opslaan
        </button>
        <button
          *ngIf="createMode"
          type="button"
          (click)="sendCreateShippingAddress(workingShippingAddress)"
          class="btn btn-outline-primary"
          [disabled]="!isFormValid()"
        >
          Toevoegen
        </button>
      </div>
    </div>
  </div>
</div>
