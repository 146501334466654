import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PAYMENT_TERMS_CHECKER_SERVICE_URL } from 'src/app/injection-tokens';
import { CustomerReportLine } from './customer-report-line';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentTermsCustomersService {
  constructor(
    @Inject(PAYMENT_TERMS_CHECKER_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getCustomerReportLines(
    paymentTerm: string
  ): Observable<CustomerReportLine[]> {
    return this.http.get<CustomerReportLine[]>(
      this.serviceURL +
        '/customers?paymentTerm=' +
        encodeURIComponent(paymentTerm)
    );
  }
}
