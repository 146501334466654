import { SCSNCustomer } from './scsn-customer.model';

export interface NewSCSNCustomerI {
  indiCustomerNumber: string;
  gln: string;
  enabled: boolean;
  ublViaSCSN: boolean;
  createdByHint: string;
  updatedByHint: string;
}

export class NewSCSNCustomer implements NewSCSNCustomerI {
  indiCustomerNumber: string;
  gln: string;
  enabled: boolean;
  ublViaSCSN: boolean;
  createdByHint: string;
  updatedByHint: string;

  constructor(
    indiCustomerNumber: string,
    gln: string,
    enabled: boolean,
    ublViaSCSN: boolean,
    createdByHint: string,
    updatedByHint: string
  ) {
    this.indiCustomerNumber = indiCustomerNumber;
    this.gln = gln;
    this.enabled = enabled;
    this.ublViaSCSN = ublViaSCSN;
    this.createdByHint = createdByHint;
    this.updatedByHint = updatedByHint;
  }
  static fromSCSNCustomer(
    scsnCustomer: SCSNCustomer,
    createdBy: string,
    updatedBy: string
  ) {
    return new NewSCSNCustomer( //
      scsnCustomer.indiCustomerNumber,
      scsnCustomer.gln,
      scsnCustomer.enabled,
      scsnCustomer.ublViaSCSN,
      createdBy,
      updatedBy
    );
  }
}
