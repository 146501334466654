import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MANCOS_SERVICE_URL } from 'src/app/injection-tokens';
import { MancosToCheckUpOn } from './manco-to-check-up-on';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MancosToCheckUpOnService {
  constructor(
    @Inject(MANCOS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getMancosToCheckUpOn(): Observable<MancosToCheckUpOn> {
    return this.http.get<MancosToCheckUpOn>(this.serviceURL);
  }
}
