<h1 class="text-center border-bottom pb-3">Specials: Kramp</h1>
<div [ngClass]="{ 'd-none': isLoading }">
  <div class="row">
    <div class="col-lg-6 col">
      <div *ngIf="reservedSpecialSku">
        <app-new-kramp-special
          [suppliers]="suppliers"
          [reservedSku]="reservedSpecialSku"
          (keepInputs)="setKeepInput($event)"
          (createNewSpecial)="createSpecial($event)"
        ></app-new-kramp-special>
      </div>
    </div>
  </div>
  <div>
    <div
      [ngClass]="{
        'd-none': !feedbackSettings.show
      }"
    >
      <div class="feedback_box">
        <app-feedback
          [(settings)]="feedbackSettings"
          (update)="handleFeedbackOutput($event)"
        ></app-feedback>
      </div>
    </div>
    <app-kramp-specials-table [specials]="specials"></app-kramp-specials-table>
  </div>
</div>

<app-loading-gears [ngClass]="{ 'd-none': !isLoading }"></app-loading-gears>
