import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SPECIALS_SERVICE_URL } from 'src/app/injection-tokens';
import { FestoSpecial } from './festo-special';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FestoSpecialsService {
  constructor(
    @Inject(SPECIALS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  createFestoSpecial(festoSpecial: FestoSpecial): Observable<FestoSpecial> {
    return this.http.post<FestoSpecial>(
      this.serviceURL + '/festo',
      festoSpecial
    );
  }

  getFestoSpecial(id: number): Observable<FestoSpecial> {
    return this.http.get<FestoSpecial>(`${this.serviceURL}/festo/${id}`);
  }

  getFestoSpecials(): Observable<FestoSpecial[]> {
    return this.http.get<FestoSpecial[]>(this.serviceURL + '/festo');
  }
}
