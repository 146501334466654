import { Pipe, PipeTransform } from '@angular/core';
import { INDISpecial } from './indi-special';

@Pipe({
  name: 'searchINDISpecials',
})
export class SearchINDISpecialsPipe implements PipeTransform {
  transform(items: INDISpecial[], searchText: string): INDISpecial[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.sku.toLowerCase().includes(searchText) ||
        it.description.toLowerCase().includes(searchText) ||
        it.supplierSKU.toLowerCase().includes(searchText) ||
        it.class2?.toLowerCase().includes(searchText) ||
        it.creationBy.toLowerCase().includes(searchText)
      );
    });
  }
}
