<div class="d-flex">
  <div class="open-close-container"></div>
  <div class="p-2">
    <div class="background-color-red p-3">
      <div>
        <div class="d-flex align-items-center justify-content-center p-3">
          <i class="fa-solid fa-shield-alt fa-2xl white"></i>
          <div class="ms-4 fs-4 white fw-bold">
            Let op: Je bent niet verbonden met de VPN
          </div>
        </div>
        <div class="small white d-flex justify-content-center p-3">
          Als het probleem zich blijft voordoen, neem dan contact op.
        </div>
      </div>
    </div>
  </div>
</div>
