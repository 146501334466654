<th scope="row">{{ index + 1 }}</th>
<td>
  {{ returnItem.orderDate | cleanDate : "date" }}
</td>
<td>
  {{ returnItem.returnNumber }}
</td>
<td>
  {{ returnItem.productNumber }}
</td>
<td>
  {{ returnItem.description }}
</td>
<td>
  {{ returnItem.orderNumber }}
</td>
<td>
  {{ returnItem.customerRef }}
</td>
<td>
  {{ returnItem.customerName }}
</td>
<td>
  {{ returnItem.quantity }}
</td>
<td>
  <button
    *ngIf="!updated; else feedback"
    class="btn btn-outline-primary"
    (click)="updateReturnItem(returnItem)"
  >
    Verwerkt
  </button>
  <ng-template #feedback>
    <app-return-update-feedback
      [success]="success"
    ></app-return-update-feedback>
  </ng-template>
</td>
