import { Component, Input } from '@angular/core';
import { ProcessedFile } from '../processedFile';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';

@Component({
  selector: 'app-processed-files',
  templateUrl: './processed-files.component.html',
  styleUrls: ['./processed-files.component.scss'],
})
export class ProcessedFilesComponent {
  @Input('processedFiles') processedFiles!: ProcessedFile[];
  @Input('isError') isError: boolean = false;
  feedbackSettings: FeedbackSettings = {
    identifier: 'processedFiles',
    show: true,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan bij het ophalen van de bestanden',
    closeButton: true,
  };
}
