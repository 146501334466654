<h1 class="text-center border-bottom pb-3">
  Alle in gebruik zijnde betaaltermijnen
</h1>
<div class="row" [ngClass]="{ 'd-none': feedbackSettings.show || isLoading }">
  <div class="w-65 p-3 col-md-8 offset-md-2">
    <p>
      Rapport over alle gebruikte waarden voor PaymentTerms in IOM. De termen in
      het <span class="text-danger">rood</span> worden niet ondersteund in
      Exact.
    </p>
    <p>
      De links zijn naar een subrapport met alle betrokken klanten. Sommige
      links worden niet gegeven omdat ze misschien niet logisch zijn: het heeft
      geen zin om 80+ klanten met goede waarden op te sommen. Het is logisch om
      2 vreemde klanten op te sommen met een niet vaak gebruikte waarde.
    </p>
  </div>
</div>
<div [ngClass]="{ 'd-none': isLoading }">
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="width-25">Payment term</th>
          <th scope="col" class="width-20">Aantal</th>
          <th scope="col" class="width-15">Actie</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let paymentTermsReportLine of paymentTermsReportLines">
          <th
            scope="row"
            [ngClass]="{ 'text-danger': !paymentTermsReportLine.valid }"
          >
            {{ paymentTermsReportLine.paymentTerm }}
          </th>
          <td class="text-end">{{ paymentTermsReportLine.count }}</td>
          <td>
            <button
              *ngIf="paymentTermsReportLine.count <= 80"
              class="btn btn-outline-primary btn-sm"
              (click)="showCustomersFor(paymentTermsReportLine.paymentTerm)"
            >
              Klanten bekijken
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback [(settings)]="feedbackSettings"></app-feedback>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
