import { Component, ViewChild } from '@angular/core';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { EmptyTUSpecial, TUSpecial } from './tu-special';
import { NewTUSpecialComponent } from './new-tu-special/new-tu-special.component';
import { TUSpecialsTableComponent } from './tu-specials-table/tu-specials-table.component';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { ReservedSpecialSku } from '../reserved-special-sku';
import { TUSpecialsService } from './tu-specials.service';
import { SpecialsService } from '../specials.service';
import { Router } from '@angular/router';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';

@Component({
  selector: 'app-tu-specials',
  templateUrl: './tu-specials.component.html',
  styleUrls: ['./tu-specials.component.scss'],
})
export class TUSpecialsComponent {
  @ViewChild(NewTUSpecialComponent)
  newTUSpecialComponent!: NewTUSpecialComponent;
  @ViewChild(TUSpecialsTableComponent)
  TUSpecialsTableComponent!: TUSpecialsTableComponent;
  @ViewChild(FeedbackComponent)
  feedbackComponent!: FeedbackComponent;
  specials: TUSpecial[] = [];
  newSpecial: TUSpecial = EmptyTUSpecial();
  failedSpecial: TUSpecial | undefined;
  keepInputs: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };
  newSpecialFeedbackIdentifier = 'newSpecialFeedback';
  generalFeedbackIdentifier = 'generalFeedback';

  reservedSpecialSku: ReservedSpecialSku | undefined;

  isLoading: boolean = false;

  constructor(
    private TUSpecialsService: TUSpecialsService,
    private specialsService: SpecialsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getReservedSpecialSku();
    this.getTUSpecials();
  }

  getTUSpecials() {
    this.isLoading = true;
    this.TUSpecialsService.getTUSpecials().subscribe(
      (specials) => {
        this.specials = specials;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is iets foutgegaan bij het ophalen van de TU specials',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  getReservedSpecialSku() {
    this.isLoading = true;
    this.specialsService.getReservedSpecialSku().subscribe(
      (reservedSpecialSku) => {
        this.reservedSpecialSku = reservedSpecialSku;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er kon geen special gereserveerd worden, probeer het later nog een keer',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  setKeepInput(boolean: boolean) {
    this.keepInputs = boolean;
  }

  createSpecial(special: TUSpecial) {
    this.isLoading = true;
    this.TUSpecialsService.createTUSpecial(special).subscribe(
      (newSpecial) => {
        this.specials.push(newSpecial);

        this.TUSpecialsTableComponent.sortByField('creationDate', false);
        this.updateFeedback(
          this.newSpecialFeedbackIdentifier,
          FeedbackType.succes,
          true,
          `De special ${newSpecial.sku} is aangemaakt!`,
          undefined,
          false
        );
        this.feedbackComponent.fadeOut();
        this.getReservedSpecialSku();
        if (!this.keepInputs) {
          this.newTUSpecialComponent.clearSpecial();
        } else {
          this.newTUSpecialComponent.clearUniqueFields();
        }
        this.isLoading = false;
      },
      (error) => {
        this.failedSpecial = special;
        console.error(error.error);
        if (error.status == 400) {
          if (
            error.error.exception != null &&
            error.error.exception.includes('DoubleDescriptionException')
          ) {
            this.updateFeedback(
              this.newSpecialFeedbackIdentifier,

              FeedbackType.error,
              true,
              `Er bestaat al een special met de omschrijving "${
                special.description
              }": ${error.error.exception.match(/\[(.*?)\]/)[1]}`,
              'Aanpassen en opnieuw proberen',
              true
            );
          } else {
            this.updateFeedback(
              this.newSpecialFeedbackIdentifier,
              FeedbackType.error,
              true,
              'Er klopte *iets* niet aan de special',
              'Aanpassen en opnieuw proberen',
              true
            );
          }
        } else {
          this.updateFeedback(
            this.newSpecialFeedbackIdentifier,
            FeedbackType.error,
            true,
            'Er is iets fout gegaan bij aanmaken van de special',
            undefined,
            true
          );
        }

        this.isLoading = false;
      }
    );
  }
  retryFailedSpecial() {
    this.feedbackSettings.show = false;
    this.failedSpecial!.creationDate = undefined;

    this.newTUSpecialComponent.retryFailedSpecial(this.failedSpecial!);
  }

  updateFeedback(
    identifier: string,
    type: FeedbackType,
    show: boolean,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ) {
    this.feedbackSettings.identifier = identifier;
    this.feedbackSettings.type = type;
    this.feedbackSettings.show = show;
    this.feedbackSettings.message = message;
    this.feedbackSettings.buttonText = buttonText;
    this.feedbackSettings.closeButton = closeButton;
  }

  handleFeedbackOutput(identifier: string) {
    if (identifier == this.newSpecialFeedbackIdentifier) {
      this.retryFailedSpecial();
    } else {
      this.returnHome();
    }
  }
  returnHome() {
    this.router.navigate(['home']);
  }

  feedbackType() {
    return FeedbackType;
  }
}
