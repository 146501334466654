<dialog #appDialog class="p-0 border-0 rounded-3 shadow-lg">
  <div class="dialog-header d-flex align-items-center px-3 py-2">
    <h1 class="h6 mb-0 flex-grow-1 text-white">
      Manco -
      {{
        data?.ticketNumber == ""
          ? "Nieuw"
          : "Freshdesk ticket:" + data?.ticketNumber
      }}
    </h1>
    <button
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="closeDialog('cancel')"
    ></button>
  </div>

  <app-manco-form
    [data]="data"
    (formSubmit)="handleSubmit($event)"
  ></app-manco-form>
</dialog>
