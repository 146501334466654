import { Pipe, PipeTransform } from '@angular/core';
import { KrampShippingAddress } from './kramp-shipping-address';

@Pipe({
  name: 'searchKrampShippingAddresses',
})
export class SearchKrampShippingAddresses implements PipeTransform {
  transform(
    items: KrampShippingAddress[],
    searchText: string
  ): KrampShippingAddress[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return (
        it.customerId!.toString().toLowerCase().includes(searchText) ||
        it.name.toLowerCase().includes(searchText) ||
        it.address.toLowerCase().includes(searchText) ||
        it.zipCode.toLowerCase().includes(searchText) ||
        it.city.toLowerCase().includes(searchText) ||
        it.shipToNo!.toLowerCase().includes(searchText) ||
        it.modifiedBy?.toLowerCase().includes(searchText)
      );
    });
  }
}
