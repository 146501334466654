import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentTermsService } from './check-payment-terms.service';
import { PaymentTermsReportLine } from './payment-terms-report-line';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';

@Component({
  selector: 'app-check-payment-terms',
  templateUrl: './check-payment-terms.component.html',
  styleUrls: ['./check-payment-terms.component.scss'],
})
export class PaymentTermsCheckerComponent implements OnInit {
  paymentTermsReportLines: PaymentTermsReportLine[] = [];

  isLoading: boolean = false;
  showFeedback: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(
    private paymentTermsService: PaymentTermsService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.getPaymentTermsReport();
  }

  updateFeedback(type: FeedbackType, message: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = true;
    this.feedbackSettings.buttonText = undefined;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  getPaymentTermsReport() {
    this.isLoading = true;
    this.paymentTermsService.getPaymentTermsReportLines().subscribe(
      (paymentTermsReportLines) => {
        this.paymentTermsReportLines = paymentTermsReportLines;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          FeedbackType.error,
          'We konden de PaymentTerms niet uit OMS ophalen.'
        );
        this.isLoading = false;
      }
    );
  }

  showCustomersFor(paymentTerm: string) {
    this.router.navigate(['betaaltermijnen-klanten'], {
      queryParams: {
        paymentTerm: encodeURIComponent(paymentTerm),
      },
    });
  }
}
