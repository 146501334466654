import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  INDI_CUSTOMERS_URL,
  SCSN_CUSTOMERS_URL,
} from 'src/app/injection-tokens';
import { Observable, forkJoin } from 'rxjs';
import { SCSNCustomer } from './scsn-customer.model';
import { NewSCSNCustomer } from './new-scsn-customer.model';
import { CustomerInfo } from './customer-info.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScsnCustomersService {
  constructor(
    @Inject(SCSN_CUSTOMERS_URL) readonly scsnCustomersServiceURL: string,
    @Inject(INDI_CUSTOMERS_URL) readonly indiCustomersServiceURL: string,
    readonly http: HttpClient
  ) {}

  arraySize = 50;

  getSCSNCustomer(id: number): Observable<SCSNCustomer> {
    return this.http.get<SCSNCustomer>(`${this.scsnCustomersServiceURL}/${id}`);
  }

  getSCSNCustomers(): Observable<SCSNCustomer[]> {
    return this.http.get<SCSNCustomer[]>(this.scsnCustomersServiceURL);
  }

  createSCSNCustomer(scsnCustomer: NewSCSNCustomer): Observable<SCSNCustomer> {
    return this.http.post<SCSNCustomer>(
      this.scsnCustomersServiceURL,
      scsnCustomer
    );
  }

  updateSCSNCustomer(
    scsnCustomer: NewSCSNCustomer,
    id: number
  ): Observable<any> {
    return this.http
      .put<any>(`${this.scsnCustomersServiceURL}/${id}`, scsnCustomer)
      .pipe(
        catchError((error) => {
          console.error('Failed to update customer:', error);
          throw error;
        })
      );
  }

  getCustomerInfo(customerNumber: string): Observable<CustomerInfo | null> {
    return this.http
      .get<CustomerInfo[]>(
        `${this.indiCustomersServiceURL}?customerNumber=${customerNumber}`
      )
      .pipe(
        map((customerInfoArray: CustomerInfo[]) =>
          customerInfoArray.length > 0 ? customerInfoArray[0] : null
        )
      );
  }

  getBulkCustomerInfo(customerNumbers: string[]): Observable<CustomerInfo[]> {
    /*This call works by adding customerNumbers to the url,
     *so we can only call about 80 customerNumbers in one call before the url get's too long.
     *So the splitArray method splits the call up into chunks of whatever preset size,
     * and the forkjoin puts the results back together in an observable of a customerInfo array.
     */

    const customerNumberArrays: string[][] = this.splitArray(customerNumbers);

    const CustomerInfoObservables: Observable<CustomerInfo[]>[] =
      customerNumberArrays.map((customerNumber) => {
        const separator: string = '&customerNumber=';
        const customerNumberUrl: string = customerNumber.join(separator);
        const customerUrl = `${this.indiCustomersServiceURL}?customerNumber=${customerNumberUrl}`;
        return this.http.get<CustomerInfo[]>(customerUrl);
      });

    return forkJoin(CustomerInfoObservables).pipe(
      map((results: CustomerInfo[][]) => {
        return results.reduce(
          (custArray1: CustomerInfo[], custArray2: CustomerInfo[]) =>
            custArray1.concat(custArray2),
          []
        );
      })
    );
  }

  private splitArray(array: string[]): string[][] {
    const arrays: string[][] = [];
    array.forEach((item, index) => {
      const ArrayIndex = Math.floor(index / this.arraySize);
      if (!arrays[ArrayIndex]) {
        arrays[ArrayIndex] = [];
      }
      arrays[ArrayIndex].push(item);
    });
    return arrays;
  }
}
