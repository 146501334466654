import { Inject, Pipe, PipeTransform } from '@angular/core';
import { BASE_ICM_URL } from 'src/app/injection-tokens';

@Pipe({
  name: 'icmCustomerLink',
})
export class IcmCustomerLinkPipe implements PipeTransform {
  constructor(@Inject(BASE_ICM_URL) readonly baseICMUrl: string) {}

  transform(customerId: string, ...args: unknown[]): unknown {
    return `${this.baseICMUrl}ViewCustomer_52-Show?CustomerID=${customerId}`;
  }
}
