<h1 class="text-center border-bottom pb-3">Price checker</h1>
<div
  [ngClass]="{ 'd-none': isLoading || hasSearched || quoteLines.length > 0 }"
  class="col-lg-6"
>
  <div>
    <p>Plak hier je clipboard data.</p>
  </div>
  <div class="d-flex flex-column">
    <div class="form-group pb-1">
      <label for="articleInfo">Producten en aantallen</label>
      <textarea
        class="form-control"
        id="articleInfo"
        rows="5"
        #articleInfoInput="ngModel"
        [(ngModel)]="articleInfoText"
        name="articleInfoName"
        placeholder="Artikel info in de vorm: SKU;aantal"
        required
        pattern="^(?:\s*[^;]+?\s*;\s*\d+(\.(\d){1,2})?\s*(?:\n|$))+$"
        [ngClass]="{
          'is-invalid': articleInfoInput.touched && !articleInfoInput.valid!
        }"
      ></textarea>
      <div class="invalid-feedback">
        In het 'Producten en aantallen'-veld mogen alleen SKUs en aantallen
        staan (gescheiden door een ';'), op elke regel 1 SKU met zijn aantal.
      </div>
      <div class="mt-3">
        <p class="m-0">Voorbeeld input:</p>
        <pre class="mt-0" style="border: 1px solid gainsboro">
SKU1;Aantal
SKU2;Aantal</pre
        >
      </div>
    </div>
    <div class="form-group align-self-end pt-1">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!articleInfoInput.valid!"
        (click)="getPrices()"
      >
        Opvragen
      </button>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback [(settings)]="feedbackSettings"></app-feedback>
</div>
<div [ngClass]="{ 'd-none': quoteLines.length <= 0 }">
  <div class="d-flex flex-column">
    <div class="form-group align-self-end pb-2">
      <button class="btn btn-primary" type="submit" (click)="newQuote()">
        Nieuwe quote
      </button>
    </div>
    <app-quote-table
      [quoteLines]="quoteLines"
      [totalCostPrice]="totalCostPrice"
    ></app-quote-table>
  </div>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
