import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ReservedSpecialSku } from '../../reserved-special-sku';
import { EmptyINDISpecial, INDISpecial } from '../indi-special';
import { AuthService } from 'src/app/auth/auth.service';
import { NgModel } from '@angular/forms';
import { Profile } from 'src/app/general-components/profile/profile';

@Component({
  selector: 'app-new-indi-special',
  templateUrl: './new-indi-special.component.html',
  styleUrls: ['./new-indi-special.component.scss'],
})
export class NewINDISpecialComponent {
  @Input('reservedSku') reservedSku: ReservedSpecialSku = { id: 0, sku: '' };
  @Output('keepInputs') keepInputs: EventEmitter<boolean> = new EventEmitter();
  @Output('createNewSpecial') createNewSpecial: EventEmitter<INDISpecial> =
    new EventEmitter();

  @ViewChild('description') description!: NgModel;
  @ViewChild('class2') class2!: NgModel;
  @ViewChild('supplierSku') supplierSku!: NgModel;
  @ViewChild('purchasePrice') purchasePrice!: NgModel;
  @ViewChild('supplierPrice') supplierPrice!: NgModel;
  @ViewChild('salesPrice') salesPrice!: NgModel;
  @ViewChild('orderUnit') orderUnit!: NgModel;

  showForm = false;

  newSpecial: INDISpecial = EmptyINDISpecial();
  profile: Profile | undefined;

  margin: number | undefined;
  searchTextSpecialCode: string = '';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.newSpecial.sku = this.reservedSku!.sku;
    this.newSpecial.id = this.reservedSku!.id;
    this.profile = this.authService.getUserInfo();
  }

  retryFailedSpecial(failedSpecial: INDISpecial) {
    this.newSpecial = failedSpecial;
  }

  toggleNewSpecial() {
    this.showForm = !this.showForm;
  }

  updatePricing() {
    this.newSpecial.supplierPrice = this.newSpecial.purchasePrice;
    this.updateSalesPrice();

    if (
      this.margin != undefined &&
      this.newSpecial.supplierPrice != undefined &&
      this.newSpecial.salesPrice != undefined
    ) {
      this.updateMargin();
    }
  }

  updateMargin() {
    if (
      this.newSpecial.supplierPrice != undefined &&
      this.newSpecial.salesPrice != undefined
    ) {
      let diff = this.newSpecial.salesPrice - this.newSpecial.supplierPrice;
      let margin = (diff / this.newSpecial.salesPrice) * 100;
      this.margin = Number(margin.toFixed(2));
    }
  }

  updateSalesPrice() {
    if (
      this.margin != undefined &&
      this.newSpecial.supplierPrice != undefined
    ) {
      let formattedMargin = (100 - this.margin) / 100;
      let newSalesPrice = this.newSpecial.supplierPrice / formattedMargin;
      this.newSpecial.salesPrice = Number(newSalesPrice.toFixed(4));
    }
  }

  toFixed(value: number, type: string) {
    switch (type) {
      case 'purchasePrice':
        this.newSpecial.purchasePrice = Number(value.toFixed(4));
        this.updatePricing();
        break;
      case 'supplierPrice':
        this.newSpecial.supplierPrice = Number(value.toFixed(4));
        this.updateMargin();
        break;
      case 'salesPrice':
        this.newSpecial.salesPrice = Number(value.toFixed(4));
        this.updateMargin();
        break;
      default:
        break;
    }
  }

  isFormValid(): boolean {
    return (
      this.description &&
      this.description.valid! &&
      this.class2 &&
      this.class2.valid! &&
      this.supplierSku &&
      this.supplierSku.valid! &&
      this.purchasePrice &&
      this.purchasePrice.valid! &&
      this.supplierPrice &&
      this.supplierPrice.valid! &&
      this.salesPrice &&
      this.salesPrice.valid! &&
      this.margin! >= 0 &&
      this.margin! <= 99.99 &&
      this.orderUnit &&
      this.orderUnit.valid!
    );
  }

  createSpecial(special: INDISpecial, keepInputs: boolean) {
    special.id = this.reservedSku.id;
    special.sku = this.reservedSku.sku;
    special.creationBy = `${this.profile?.firstName} ${this.profile?.lastName}`;
    this.keepInputs.emit(keepInputs);
    this.createNewSpecial.emit(this.cleanUpSpecial(special));
  }

  clearSpecial() {
    this.newSpecial = EmptyINDISpecial();
    this.description.reset();
    this.class2.reset();
    this.supplierSku.reset();
    this.purchasePrice.reset();
    this.supplierPrice.reset();
    this.salesPrice.reset();
    this.searchTextSpecialCode = '';
    this.margin = undefined;
  }

  clearUniqueFields() {
    this.description.reset();
  }

  cleanUpSpecial(special: INDISpecial): INDISpecial {
    special.description = special.description.trim();
    return special;
  }
}
