import { Component, OnInit, ViewChild } from '@angular/core';
import { SupplierService } from '../suppliers/supplier.service';
import { Router } from '@angular/router';
import { KrampSpecialsService } from './kramp-specials.service';
import { EmptyKrampSpecial, KrampSpecial } from './kramp-special';
import { ReservedSpecialSku } from '../reserved-special-sku';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { NewKrampSpecialComponent } from './new-kramp-special/new-kramp-special.component';
import { KrampSpecialsTableComponent } from './kramp-specials-table/kramp-specials-table.component';
import { Supplier } from '../suppliers/supplier';
import { SpecialsService } from '../specials.service';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';

@Component({
  selector: 'app-kramp-specials',
  templateUrl: './kramp-specials.component.html',
  styleUrls: ['./kramp-specials.component.scss'],
})
export class KrampSpecialsComponent implements OnInit {
  @ViewChild(NewKrampSpecialComponent)
  newKrampSpecialComponent!: NewKrampSpecialComponent;
  @ViewChild(KrampSpecialsTableComponent)
  krampSpecialsTableComponent!: KrampSpecialsTableComponent;
  @ViewChild(FeedbackComponent)
  feedbackComponent!: FeedbackComponent;
  specials: KrampSpecial[] = [];
  newSpecial: KrampSpecial = EmptyKrampSpecial();
  failedSpecial: KrampSpecial | undefined;
  keepInputs: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };
  newSpecialFeedbackIdentifier = 'newSpecialFeedback';
  generalFeedbackIdentifier = 'generalFeedback';

  reservedSpecialSku: ReservedSpecialSku | undefined;

  suppliers: Supplier[] = [];
  isLoading: boolean = false;

  constructor(
    private supplierService: SupplierService,
    private krampSpecialsService: KrampSpecialsService,
    private specialsService: SpecialsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getReservedSpecialSku();
    this.getSuppliers();
    this.getKrampSpecials();
  }

  getKrampSpecials() {
    this.isLoading = true;
    this.krampSpecialsService.getKrampSpecials().subscribe(
      (specials) => {
        this.specials = specials;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is iets foutgegaan bij het ophalen van de Kramp specials',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  getSuppliers() {
    this.isLoading = true;
    this.supplierService.getSuppliers().subscribe(
      (suppliers) => {
        this.suppliers = suppliers.sort((supplierA, supplierB) =>
          supplierA.name > supplierB.name ? 1 : -1
        );
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is iets fout gegaan bij het ophalen van de leveranciers',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  getReservedSpecialSku() {
    this.isLoading = true;
    this.specialsService.getReservedSpecialSku().subscribe(
      (reservedSpecialSku) => {
        this.reservedSpecialSku = reservedSpecialSku;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er kon geen special gereserveerd worden, probeer het later nog een keer',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  setKeepInput(boolean: boolean) {
    this.keepInputs = boolean;
  }

  createSpecial(special: KrampSpecial) {
    this.isLoading = true;
    this.krampSpecialsService.createKrampSpecial(special).subscribe(
      (newSpecial) => {
        this.specials.push(newSpecial);

        this.krampSpecialsTableComponent.sortByField('creationDate', false);
        this.updateFeedback(
          this.newSpecialFeedbackIdentifier,
          FeedbackType.succes,
          true,
          `De special ${newSpecial.sku} is aangemaakt!`,
          undefined,
          false
        );
        this.feedbackComponent.fadeOut();
        this.getReservedSpecialSku();
        if (!this.keepInputs) {
          this.newKrampSpecialComponent.clearSpecial();
        } else {
          this.newKrampSpecialComponent.clearUniqueFields();
        }
        this.isLoading = false;
      },
      (error) => {
        this.failedSpecial = special;
        console.error(error.error);
        if (error.status == 400) {
          if (
            error.error.exception != null &&
            error.error.exception.includes('DoubleDescriptionException')
          ) {
            this.updateFeedback(
              this.newSpecialFeedbackIdentifier,

              FeedbackType.error,
              true,
              `Er bestaat al een special met de omschrijving "${
                special.description
              }": ${error.error.exception.match(/\[(.*?)\]/)[1]}`,
              'Aanpassen en opnieuw proberen',
              true
            );
          } else {
            this.updateFeedback(
              this.newSpecialFeedbackIdentifier,
              FeedbackType.error,
              true,
              'Er klopte *iets* niet aan de special',
              'Aanpassen en opnieuw proberen',
              true
            );
          }
        } else {
          this.updateFeedback(
            this.newSpecialFeedbackIdentifier,
            FeedbackType.error,
            true,
            'Er is iets fout gegaan bij aanmaken van de special',
            undefined,
            true
          );
        }

        this.isLoading = false;
      }
    );
  }
  retryFailedSpecial() {
    this.feedbackSettings.show = false;
    this.failedSpecial!.creationDate = undefined;

    this.newKrampSpecialComponent.retryFailedSpecial(this.failedSpecial!);
  }

  updateFeedback(
    identifier: string,
    type: FeedbackType,
    show: boolean,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ) {
    this.feedbackSettings.identifier = identifier;
    this.feedbackSettings.type = type;
    this.feedbackSettings.show = show;
    this.feedbackSettings.message = message;
    this.feedbackSettings.buttonText = buttonText;
    this.feedbackSettings.closeButton = closeButton;
  }

  handleFeedbackOutput(identifier: string) {
    if (identifier == this.newSpecialFeedbackIdentifier) {
      this.retryFailedSpecial();
    } else {
      this.returnHome();
    }
  }
  returnHome() {
    this.router.navigate(['home']);
  }

  feedbackType() {
    return FeedbackType;
  }
}
