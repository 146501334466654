import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PAYMENT_TERMS_CHECKER_SERVICE_URL } from 'src/app/injection-tokens';
import { PaymentTermsReportLine } from './payment-terms-report-line';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentTermsService {
  constructor(
    @Inject(PAYMENT_TERMS_CHECKER_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getPaymentTermsReportLines(): Observable<PaymentTermsReportLine[]> {
    return this.http.get<PaymentTermsReportLine[]>(this.serviceURL);
  }
}
