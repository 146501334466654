import { Inject, Pipe, PipeTransform } from '@angular/core';
import { BASE_OMS_URL } from 'src/app/injection-tokens';

@Pipe({
  name: 'omsOrderLink',
})
export class OmsOrderLinkPipe implements PipeTransform {
  constructor(@Inject(BASE_OMS_URL) readonly baseOMSUrl: string) {}

  transform(orderId: number, ...args: unknown[]): unknown {
    return `${this.baseOMSUrl}order/${orderId}/10001/`;
  }
}
