import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/auth/auth.service';
import { EmptyProfile, Profile } from './profile';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profile: Profile = EmptyProfile();
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.profile = this.authService.getUserInfo();
  }
}
