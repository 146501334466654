import { Component, ViewChild } from '@angular/core';
import { SOSEmailService } from '../sos-email.service';
import { NgModel } from '@angular/forms';
import { SOSCustomer } from '../sos-customer';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';

@Component({
  selector: 'app-sos-email-kramp',
  templateUrl: './sos-email-kramp.component.html',
  styleUrls: ['./sos-email-kramp.component.scss'],
})
export class SOSEmailKrampComponent {
  @ViewChild('krampOrdernumbersInput') krampOrdernumbersInput!: NgModel;

  krampOrdernumbers: string = '';
  didSearch: boolean = false;
  sosCustomers: SOSCustomer[] = [];

  isLoading: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };

  constructor(private service: SOSEmailService) {}

  updateFeedback(type: FeedbackType, message: string) {
    this.feedbackSettings.identifier = '';
    this.feedbackSettings.show = true;
    this.feedbackSettings.closeButton = true;
    this.feedbackSettings.buttonText = undefined;

    this.feedbackSettings.type = type;
    this.feedbackSettings.message = message;
  }

  getSOSCustomers() {
    this.isLoading = true;
    this.didSearch = false;
    this.service
      .getSOSCustomersByKrampOrdernumbers(this.krampOrdernumbers)
      .subscribe(
        (sosCustomers) => {
          this.didSearch = true;
          this.sosCustomers = sosCustomers;
          this.isLoading = false;
        },
        (error) => {
          this.updateFeedback(
            FeedbackType.error,
            'Er is *iets* fout gegaan bij het ophalen van de order informatie'
          );
          this.isLoading = false;
        }
      );
  }
}
