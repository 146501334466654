<h1 class="text-center border-bottom pb-3">SOS-mail Kramp-ordernummers</h1>
<div
  class="row"
  [ngClass]="{ 'd-none': feedbackSettings.show || sosCustomers.length > 0 }"
>
  <div class="w-65 p-3 col-md-8 offset-md-2">
    <p class="mb-0">
      Maak een
      <a href="https://en.wikipedia.org/wiki/Mailto" target="_blank"
        >mailto-link</a
      >
      aan de hand van Kramp-ordernummers.
    </p>
    <p class="mb-1">
      Plak alle Kramp-ordernummers in het onderstaande teksts veld en druk op
      'Opvragen'
    </p>
    <div class="d-flex flex-column">
      <div class="form-group pb-1">
        <label for="krampOrdernumbersId">Kramp ordernummers</label>
        <textarea
          class="form-control"
          id="krampOrdernumbersId"
          rows="5"
          #krampOrdernumbersInput="ngModel"
          [(ngModel)]="krampOrdernumbers"
          name="krampOrdernumbersName"
          placeholder="Kramp ordernummers hier"
          required
          pattern="(\s*[\d\n]+\s*)+"
          [ngClass]="{
            'is-invalid':
              krampOrdernumbersInput.touched && !krampOrdernumbersInput.valid!
          }"
        ></textarea>
        <div class="invalid-feedback">
          <!-- text-danger is-invalid -->
          In het 'Kramp ordernummers'-veld mogen alleen Kramp ordernummers
          staan, op elke regel 1.
        </div>
      </div>
      <div class="form-group align-self-end pt-1">
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="!krampOrdernumbersInput.valid!"
          (click)="getSOSCustomers()"
        >
          Opvragen
        </button>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback [(settings)]="feedbackSettings"></app-feedback>
</div>
<div [ngClass]="{ 'd-none': sosCustomers.length <= 0 }">
  <app-sos-customer-list
    [didSearch]="didSearch"
    [sosCustomers]="sosCustomers"
  ></app-sos-customer-list>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
