export interface TUSpecial {
  id: number;
  sku: string;
  creationDate?: string;
  creationBy: string;
  supplier: string;
  description: string;
  class2: string;
  supplierSKU: string;
  purchasePrice?: number;
  supplierPrice?: number;
  salesPrice?: number;
  orderUnit: number;
  deliveryDays: number;
}

export const EmptyTUSpecial = (): TUSpecial => ({
  id: 1,
  sku: '',
  creationBy: '',
  supplier: 'SUPPLIER_TU',
  description: '',
  class2: '',
  supplierSKU: '',
  orderUnit: 1,
  deliveryDays: 0,
});
