import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RETURNS_SERVICE_URL } from 'src/app/injection-tokens';
import { Observable } from 'rxjs';
import { Return } from './return';
import { ReturnItem } from './returnItem';
import { ProcessedFile } from './processedFile';

@Injectable({
  providedIn: 'root',
})
export class ReturnsService {
  constructor(
    @Inject(RETURNS_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getProcessedFiles(limit: number): Observable<ProcessedFile[]> {
    return this.http.get<ProcessedFile[]>(
      `${this.serviceURL}/processed-files?limit=${limit}`
    );
  }

  getUnprocessedReturns(): Observable<Return[]> {
    return this.http.get<Return[]>(this.serviceURL);
  }

  setProcessed(returnItem: ReturnItem): Observable<HttpResponse<any>> {
    return this.http.put<any>(
      `${this.serviceURL}/mark-processed/${returnItem.returnId}`,
      { observe: 'response' } //
    );
  }
}
