import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { ConnectionStatusService } from './general-components/connection-status/service/connection-status.service';
import { FeedbackSettings } from './general-components/feedback/feedback-settings';
import { FeedbackType } from './general-components/feedback/feedback-type';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'INDI addons SSO Proof of Concept';
  story = 'lets try that again..';
  loginLoading: boolean = true;
  intervalSubscription!: Subscription;
  readonly intervalTime: number = 30000;
  readonly restartIntervalTime: number = 5000;
  showConnectionError: boolean = false;

  constructor(
    private authService: AuthService,
    private connectionStatusService: ConnectionStatusService
  ) {}

  ngOnInit(): void {
    this.authService.isLoading().subscribe((bool: boolean) => {
      this.loginLoading = bool;
    });
  }

  ngAfterViewInit(): void {
    this.getCurrentConnectionStatus();
    this.startIntervalSubscription();
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
  }

  getUserName(): string {
    let userProfile = this.authService.getUserInfo();
    return `${userProfile.firstName} ${userProfile.lastName}`;
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  startIntervalSubscription(): void {
    this.intervalSubscription = interval(this.intervalTime).subscribe({
      next: () => {
        this.getCurrentConnectionStatus();
      },
      error: (error) => {
        // This will probably never be reached
        console.error('Error in interval subscription. Restarting...', error);
        this.restartInterval();
      },
    });
  }

  restartInterval(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    setTimeout(
      () => this.startIntervalSubscription(),
      this.restartIntervalTime
    );
  }

  getCurrentConnectionStatus(): void {
    this.connectionStatusService
      .isConnectionStatusUp()
      .subscribe((isConnected) => {
        this.showConnectionError = !isConnected;
      });
  }
}
