<div *ngIf="specials.length > 0">
  <div class="d-flex justify-content-end mb-3">
    <div class="searchbar">
      <input
        class="search_input"
        type="text"
        [(ngModel)]="searchText"
        placeholder="Zoeken.."
      />
      <div class="search_icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('sku', !SortBySkuAsc)"
        >
          SKU
          <div
            #sortIcons
            id="skuAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="skuDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-15 sort"
          (click)="sortByField('description', !sortByDescriptionAsc)"
        >
          description
          <div
            #sortIcons
            id="descriptionAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="descriptionDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('supplierSku', !sortBySupplierSkuAsc)"
        >
          part number
          <div
            #sortIcons
            id="supplierSkuAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="supplierSkuDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('supplierPrice', !sortBySupplierPriceAsc)"
        >
          cost price
          <div
            #sortIcons
            id="supplierPriceAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="supplierPriceDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('salesPrice', !sortBySalesPriceAsc)"
        >
          list price
          <div
            #sortIcons
            id="salesPriceAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="salesPriceDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-5 sort"
          (click)="sortByField('deliveryDays', !sortByDeliveryDaysAsc)"
        >
          days
          <div
            #sortIcons
            id="deliveryDaysAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="deliveryDaysDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('creationBy', !sortByCreationByAsc)"
        >
          created by
          <div
            #sortIcons
            id="creationByAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="creationByDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('creationDate', !sortByCreationDateAsc)"
        >
          created on
          <div
            #sortIcons
            id="creationDateAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="creationDateDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let special of specials
            | searchINDISpecials : searchText
            | paginate : { itemsPerPage: 10, currentPage: page }
        "
      >
        <th scope="row">{{ special.sku }}</th>
        <td>{{ special.description }}</td>
        <td>{{ special.supplierSKU }}</td>
        <td>{{ special.supplierPrice }}</td>
        <td>{{ special.salesPrice }}</td>
        <td>{{ special.deliveryDays < 0 ? "n/a" : special.deliveryDays }}</td>
        <td>{{ special.creationBy }}</td>
        <td>
          {{ special.creationDate! | utcDate : "yyyy-MM-dd HH:mm:ss" }}
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls
    (pageChange)="page = $event"
    previousLabel="Vorige"
    nextLabel="Volgende"
    [autoHide]="true"
  ></pagination-controls>
</div>
