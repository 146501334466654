import { addDays, format, parse } from 'date-fns';
import { Pipe, PipeTransform } from '@angular/core';
import { MancoToCheckUpOn } from '../manco-to-check-up-on';

export enum MancoFilters {
  AllMancos = "Alle Manco's",
  MancosNext3Days = "Manco's voor komende 3 dagen",
}

@Pipe({
  name: 'mancoFilter',
})
export class MancoFilterPipe implements PipeTransform {
  transform(
    mancos: MancoToCheckUpOn[],
    selectedFilter: MancoFilters
  ): MancoToCheckUpOn[] {
    if (selectedFilter == MancoFilters.AllMancos) return mancos;

    const newDate = addDays(this.today(), 4);
    const checkDate = format(newDate, 'yyyy-MM-dd');

    return mancos.filter((manco) => {
      return (
        !manco.booked && //
        manco.checkDate <= checkDate
      );
    });
  }
  today() {
    // TODO: Change to new Date() on release to PROD
    return parse('2024-09-14', 'yyyy-MM-dd', new Date());
  }
}
