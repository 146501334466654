import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean, lang: string): string {
    switch (lang) {
      case 'en':
        return value ? 'Yes' : 'No';
      case 'nl':
        return value ? 'Ja' : 'Nee';
      default:
        return value ? 'Ja' : 'Nee';
    }
  }
}
