import {
  Component,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { KrampSpecial } from '../kramp-special';

@Component({
  selector: 'app-kramp-specials-table',
  templateUrl: './kramp-specials-table.component.html',
  styleUrls: ['./kramp-specials-table.component.scss'],
})
export class KrampSpecialsTableComponent {
  @ViewChildren('sortIcons') sortIcons!: QueryList<ElementRef>;
  @Input('specials') specials: KrampSpecial[] = [];

  page: number = 1;
  searchText: string = '';
  SortBySkuAsc: boolean = false;
  sortByDescriptionAsc: boolean = false;
  sortByKrampSupplierSkuAsc: boolean = false;
  sortByKrampSpecialCodeAsc: boolean = false;
  sortByKrampSupplierAsc: boolean = false;
  sortBySupplierPriceAsc: boolean = false;
  sortBySalesPriceAsc: boolean = false;
  sortByDeliveryDaysAsc: boolean = false;
  sortByCreationByAsc: boolean = false;
  sortByCreationDateAsc: boolean = false;

  constructor(private renderer: Renderer2) {}

  sortByField(field: string, ascendingOrder: boolean) {
    switch (field) {
      case 'sku': {
        this.SortBySkuAsc = ascendingOrder;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationDateAsc = false;
        this.sortByCreationByAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.description! < special2.description!) return -1;
            if (special1.description! > special2.description!) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.creationDate! < special2.creationDate!) return 1;
            if (special1.creationDate! > special2.creationDate!) return -1;
            return 0;
          });
        }
        break;
      }
      case 'description': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = ascendingOrder;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationDateAsc = false;
        this.sortByCreationByAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.description! < special2.description!) return -1;
            if (special1.description! > special2.description!) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.creationDate! < special2.creationDate!) return 1;
            if (special1.creationDate! > special2.creationDate!) return -1;
            return 0;
          });
        }
        break;
      }
      case 'krampSupplierSku': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = ascendingOrder;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationByAsc = false;
        this.sortByCreationDateAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.krampSupplierSku < special2.krampSupplierSku)
              return -1;
            if (special1.krampSupplierSku > special2.krampSupplierSku) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.krampSupplierSku < special2.krampSupplierSku) return 1;
            if (special1.krampSupplierSku > special2.krampSupplierSku)
              return -1;
            return 0;
          });
        }
        break;
      }
      case 'krampSpecialCode': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = ascendingOrder;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationByAsc = false;
        this.sortByCreationDateAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.krampSpecialCode < special2.krampSpecialCode)
              return -1;
            if (special1.krampSpecialCode > special2.krampSpecialCode) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.krampSpecialCode < special2.krampSpecialCode) return 1;
            if (special1.krampSpecialCode > special2.krampSpecialCode)
              return -1;
            return 0;
          });
        }
        break;
      }
      case 'krampSupplier': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = ascendingOrder;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationDateAsc = false;
        this.sortByCreationByAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.krampSupplierName! < special2.krampSupplierName!)
              return -1;
            if (special1.krampSupplierName! > special2.krampSupplierName!)
              return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.krampSupplierName! < special2.krampSupplierName!)
              return 1;
            if (special1.krampSupplierName! > special2.krampSupplierName!)
              return -1;
            return 0;
          });
        }
        break;
      }
      case 'supplierPrice': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = ascendingOrder;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationDateAsc = false;
        this.sortByCreationByAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.supplierPrice! < special2.supplierPrice!) return -1;
            if (special1.supplierPrice! > special2.supplierPrice!) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.supplierPrice! < special2.supplierPrice!) return 1;
            if (special1.supplierPrice! > special2.supplierPrice!) return -1;
            return 0;
          });
        }
        break;
      }
      case 'salesPrice': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = ascendingOrder;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationDateAsc = false;
        this.sortByCreationByAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.salesPrice! < special2.salesPrice!) return -1;
            if (special1.salesPrice! > special2.salesPrice!) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.salesPrice! < special2.salesPrice!) return 1;
            if (special1.salesPrice! > special2.salesPrice!) return -1;
            return 0;
          });
        }
        break;
      }
      case 'deliveryDays': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = ascendingOrder;
        this.sortByCreationDateAsc = false;
        this.sortByCreationByAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.deliveryDays! < special2.deliveryDays!) return -1;
            if (special1.deliveryDays! > special2.deliveryDays!) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.deliveryDays! < special2.deliveryDays!) return 1;
            if (special1.deliveryDays! > special2.deliveryDays!) return -1;
            return 0;
          });
        }
        break;
      }
      case 'creationBy': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationByAsc = ascendingOrder;
        this.sortByCreationDateAsc = false;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.creationBy < special2.creationBy) return -1;
            if (special1.creationBy > special2.creationBy) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.creationBy < special2.creationBy) return 1;
            if (special1.creationBy > special2.creationBy) return -1;
            return 0;
          });
        }
        break;
      }
      case 'creationDate': {
        this.SortBySkuAsc = false;
        this.sortByDescriptionAsc = false;
        this.sortByKrampSupplierSkuAsc = false;
        this.sortByKrampSpecialCodeAsc = false;
        this.sortByKrampSupplierAsc = false;
        this.sortBySupplierPriceAsc = false;
        this.sortBySalesPriceAsc = false;
        this.sortByDeliveryDaysAsc = false;
        this.sortByCreationByAsc = false;
        this.sortByCreationDateAsc = ascendingOrder;

        if (ascendingOrder) {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.creationDate! < special2.creationDate!) return -1;
            if (special1.creationDate! > special2.creationDate!) return 1;
            return 0;
          });
        } else {
          this.specials = this.specials.sort((special1, special2) => {
            if (special1.creationDate! < special2.creationDate!) return 1;
            if (special1.creationDate! > special2.creationDate!) return -1;
            return 0;
          });
        }
        break;
      }

      default: {
        //statements;
        break;
      }
    }
    this.updateSortingHighlight(`${field}${ascendingOrder ? 'Asc' : 'Desc'}`);
  }

  updateSortingHighlight(highlightElement: string) {
    this.sortIcons.forEach((element: ElementRef) => {
      if (element.nativeElement.id == highlightElement) {
        this.renderer.addClass(element.nativeElement, 'highlight');
      } else {
        this.renderer.removeClass(element.nativeElement, 'highlight');
      }
    });
  }
}
