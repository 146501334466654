import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RETURN_FAILURES_SERVICE_URL } from 'src/app/injection-tokens';
import { ReturnFailure } from './return-failure';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReturnFailuresService {
  constructor(
    @Inject(RETURN_FAILURES_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getReturnFailures(): Observable<ReturnFailure[]> {
    return this.http.get<ReturnFailure[]>(this.serviceURL);
  }
}
