import { Component, ViewChild } from '@angular/core';
import { NewFestoSpecialComponent } from './new-festo-special/new-festo-special.component';
import { FestoSpecialsTableComponent } from './festo-specials-table/festo-specials-table.component';
import { EmptyFestoSpecial, FestoSpecial } from './festo-special';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { ReservedSpecialSku } from '../reserved-special-sku';
import { FestoSpecialsService } from './festo-specials.service';
import { SpecialsService } from '../specials.service';
import { Router } from '@angular/router';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';

@Component({
  selector: 'app-festo-specials',
  templateUrl: './festo-specials.component.html',
  styleUrls: ['./festo-specials.component.scss'],
})
export class FestoSpecialsComponent {
  @ViewChild(NewFestoSpecialComponent)
  newFestoSpecialComponent!: NewFestoSpecialComponent;
  @ViewChild(FestoSpecialsTableComponent)
  festoSpecialsTableComponent!: FestoSpecialsTableComponent;
  @ViewChild(FeedbackComponent)
  feedbackComponent!: FeedbackComponent;
  specials: FestoSpecial[] = [];
  newSpecial: FestoSpecial = EmptyFestoSpecial();
  failedSpecial: FestoSpecial | undefined;
  keepInputs: boolean = false;

  feedbackSettings: FeedbackSettings = {
    identifier: '',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets misgegaan sorry',
    closeButton: false,
  };
  newSpecialFeedbackIdentifier = 'newSpecialFeedback';
  generalFeedbackIdentifier = 'generalFeedback';

  reservedSpecialSku: ReservedSpecialSku | undefined;

  isLoading: boolean = false;

  constructor(
    private festoSpecialsService: FestoSpecialsService,
    private specialsService: SpecialsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getReservedSpecialSku();
    this.getFestoSpecials();
  }

  getFestoSpecials() {
    this.isLoading = true;
    this.festoSpecialsService.getFestoSpecials().subscribe(
      (specials) => {
        this.specials = specials;
        this.isLoading = false;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er is iets foutgegaan bij het ophalen van de Festo specials',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  getReservedSpecialSku() {
    this.isLoading = true;
    this.specialsService.getReservedSpecialSku().subscribe(
      (reservedSpecialSku) => {
        this.reservedSpecialSku = reservedSpecialSku;
      },
      (error) => {
        this.updateFeedback(
          this.generalFeedbackIdentifier,
          FeedbackType.error,
          true,
          'Er kon geen special gereserveerd worden, probeer het later nog een keer',
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
      }
    );
  }

  setKeepInput(boolean: boolean) {
    this.keepInputs = boolean;
  }

  createSpecial(special: FestoSpecial) {
    this.isLoading = true;
    this.festoSpecialsService.createFestoSpecial(special).subscribe(
      (newSpecial) => {
        this.specials.push(newSpecial);

        this.festoSpecialsTableComponent.sortByField('creationDate', false);
        this.updateFeedback(
          this.newSpecialFeedbackIdentifier,
          FeedbackType.succes,
          true,
          `De special ${newSpecial.sku} is aangemaakt!`,
          undefined,
          false
        );
        this.feedbackComponent.fadeOut();
        this.getReservedSpecialSku();
        if (!this.keepInputs) {
          this.newFestoSpecialComponent.clearSpecial();
        } else {
          this.newFestoSpecialComponent.clearUniqueFields();
        }
        this.isLoading = false;
      },
      (error) => {
        this.failedSpecial = special;
        console.error(error.error);
        if (error.status == 400) {
          if (
            error.error.exception != null &&
            error.error.exception.includes('DoubleDescriptionException')
          ) {
            this.updateFeedback(
              this.newSpecialFeedbackIdentifier,

              FeedbackType.error,
              true,
              `Er bestaat al een special met de omschrijving "${
                special.description
              }": ${error.error.exception.match(/\[(.*?)\]/)[1]}`,
              'Aanpassen en opnieuw proberen',
              true
            );
          } else {
            this.updateFeedback(
              this.newSpecialFeedbackIdentifier,
              FeedbackType.error,
              true,
              'Er klopte *iets* niet aan de special',
              'Aanpassen en opnieuw proberen',
              true
            );
          }
        } else {
          this.updateFeedback(
            this.newSpecialFeedbackIdentifier,
            FeedbackType.error,
            true,
            'Er is iets fout gegaan bij aanmaken van de special',
            undefined,
            true
          );
        }

        this.isLoading = false;
      }
    );
  }
  retryFailedSpecial() {
    this.feedbackSettings.show = false;
    this.failedSpecial!.creationDate = undefined;

    this.newFestoSpecialComponent.retryFailedSpecial(this.failedSpecial!);
  }

  updateFeedback(
    identifier: string,
    type: FeedbackType,
    show: boolean,
    message: string,
    buttonText: string | undefined,
    closeButton: boolean
  ) {
    this.feedbackSettings.identifier = identifier;
    this.feedbackSettings.type = type;
    this.feedbackSettings.show = show;
    this.feedbackSettings.message = message;
    this.feedbackSettings.buttonText = buttonText;
    this.feedbackSettings.closeButton = closeButton;
  }

  handleFeedbackOutput(identifier: string) {
    if (identifier == this.newSpecialFeedbackIdentifier) {
      this.retryFailedSpecial();
    } else {
      this.returnHome();
    }
  }
  returnHome() {
    this.router.navigate(['home']);
  }

  feedbackType() {
    return FeedbackType;
  }
}
